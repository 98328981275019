import React, {useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import { getTeamById, putTeam } from "../Api/Teams";
import TeamForm from "../components/TeamForm";
import UserList from "../components/UserList";
import { getUsers } from "../Api/User";
import { toast } from "react-toastify";
import UserSuggestion from "../components/UserSuggestion";
import _ from "lodash";

function TeamEdit(){

    const { teamId } = useParams(); // get Id from url

    const [name, setName] = useState("");
    const [users, setUsers] = useState([]);
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [fakeRefresh, setFakeRefresh] = useState(0);

    const fetchTeam = async() => {

        const response = await getTeamById({teamId});
    
        setName(response.data.name);
        setUsers(response.data.users);
        setUsersLoaded(true);
    }

    const fetchAllUsers = async () => {

        const response = await getUsers();

        setAllUsers(response.data);

    }

    useEffect(() => {

        fetchTeam();
        fetchAllUsers();

    }, [fakeRefresh]) 
    // need to have fakeRefresh as useEffect array param cos everytime a user is added or removed,
    // the users need to be refreshed in case it needs to be added back in.


    const saveTeam = async (updatedName, updatedUsers) => {

        try{
            await putTeam({
                teamId : teamId, 
                name: updatedName, 
                users: updatedUsers
            });

            toast.success('Changes saved.');

        }
        catch(error){
            toast.error(error.message);
        }
    }

    const onSaveTeamName = async () =>{

        await saveTeam(name, users);
    }

    const onAddUserToTeam = async (selectedUser) =>{

        // update backend
        const updatedUsers = [...users, selectedUser];

        await saveTeam(name, updatedUsers);

        // update react state
        setUsers( (value) => [...value, selectedUser]);

    }

    const onRemoveUserFromTeam = async (selectedUser) =>{

        console.log(selectedUser);

        const updatedUsers =_.remove(users, (userItem) => { 
            return userItem.userId != selectedUser.userId
        });

        await saveTeam(name, updatedUsers);

        setUsers(updatedUsers);

        // update react state to force useEffect to refresh
        setFakeRefresh(1);
    }


    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">
                    
                    <div className="settings-content-container px-6 w-full">

                        <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Team</h3>
                        
                        <TeamForm name={name} onNameChange={setName} onSave={onSaveTeamName} />

                        <div>

                            <div className="flex mt-11 mb-4 border-b pb-4">

                                <div className="title-area flex-grow">
                                    <h3 className="text-2xl font-semibold leading-8">People</h3>
                                </div>
                                
                                
                            </div>

                            <UserSuggestion users={allUsers} onAddUserClick={onAddUserToTeam} />
                            
                            <UserList users={users} usersLoaded={usersLoaded} onUserDelete={onRemoveUserFromTeam}  />
                        </div>
                        
                    </div>
                </div>

            </div>

        </div>
    )

    
}

export default TeamEdit;