import React from 'react';
import { ToastProvider, useToasts, DefaultToast } from 'react-toast-notifications'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Login from './pages/Login.js';
import Register from './pages/Register.js';
import InvitationRegister from './pages/InvitationRegister.js';
import InvitationLogIn from './pages/InvitationLogin.js';
import GoogleLoginRedirect from './components/GoogleLoginRedirect.js';
import GoogleCalSyncRedirect from './components/GoogleCalSyncRedirect.js';
import Dashboard from './pages/Dashboard2.js';
import Settings from './pages/SettingsPage.js';
import SettingsMember from './pages/SettingsMemberPage';
import Floors from './pages/Floors';
import Organisation from './components/Organisation.js';
import Analytics from './components/Analytics.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isAuthed } from './utils/Utils';
import PrivateRoute from './components/PrivateRoute';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import ForgotPassword from './pages/ForgotPassword.js';
import ForgotPasswordSent from './pages/ForgotPasswordSent.js';
import ResetPassword from './pages/ResetPassword.js';
import FloorAdd from './pages/FloorAdd.js';
import FloorEdit from './pages/FloorEdit.js';
import TeamEdit from './pages/TeamEdit.js';
import TeamAdd from './pages/TeamAdd';
import FirstSetupUser from './pages/FirstSetupUser.js';
import FirstSetupOrg from './pages/FirstSetupOrg.js';
import AnalyticsNew from './components/AnalyticsNew.js';

function App() {

  // if (process.env.NODE_ENV === 'production'){
  //   // https://github.com/react-ga/react-ga
  //   ReactGA.initialize('G-PXVNDD5F39');
  //   ReactGA.set({ anonymizeIp: true });
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }

  // const isProductionEnv = true;
  const isProductionEnv = process.env.NODE_ENV === 'production';


  return (
      <div>

        <Helmet>
          <title>OfficeMixer</title>
          <meta name="description" content="" />
          { isProductionEnv ? <script async src="https://www.googletagmanager.com/gtag/js?id=G-PXVNDD5F39"></script> : null }
          { isProductionEnv ? <script src="google-analytics.js"></script> : null}
        </Helmet>

        <Router>

          <Switch>
            
            <Route path="/" exact component={Login} />

            <PrivateRoute authed={isAuthed()} path="/calendar" exact component={Dashboard} />

            <Route path="/login" exact component={Login} />

            <Route path="/register" exact component={Register} />

            <Route path="/forgot-password" exact component={ForgotPassword} />

            <Route path="/forgot-password-sent" exact component={ForgotPasswordSent} />

            <Route path="/reset-password" exact component={ResetPassword} />
            
            
            <Route path="/invitations-register" exact component={InvitationRegister} />

            <Route path="/invitations-signin" exact component={InvitationLogIn} />

            {/* This is the redirect url from Google */}
            <Route path="/google-auth-redirect" exact component={GoogleLoginRedirect} />

            {/* This is the redirect url from Google */}
            <Route path="/google-cal-redirect" exact component={GoogleCalSyncRedirect} />


            <Route path="/user-setup" exact component={FirstSetupUser} />
            <Route path="/org-setup" exact component={FirstSetupOrg} />

            <PrivateRoute authed={isAuthed()} path="/organisation" exact component={Floors} />
            <PrivateRoute authed={isAuthed()} path="/organisation/floors/add" exact component={FloorAdd} />
            <PrivateRoute authed={isAuthed()} path="/organisation/floors/:floorId" exact component={FloorEdit} />

            <PrivateRoute authed={isAuthed()} path="/teams/add" exact component={TeamAdd} />
            <PrivateRoute authed={isAuthed()} path="/teams/:teamId" exact component={TeamEdit} />
            

            <PrivateRoute authed={isAuthed()} path="/settings/members" exact component={SettingsMember} />

            <PrivateRoute authed={isAuthed()} path="/settings" exact component={Settings} />

            <PrivateRoute authed={isAuthed()} path="/analytics" exact component={AnalyticsNew} />
            
          </Switch>
          
        </Router>
        <ToastContainer />
        
      </div>
  );
}

export default App;
