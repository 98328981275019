import React, { useEffect} from "react"
import {  useHistory } from 'react-router-dom'
import { exchangeForGoogleToken } from "../utils/Api.js";
import { acceptInvite } from "../Api/Invitations";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from "qs";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'black',
    },
  }));

toast.configure();

function GoogleLoginRedirect(){

    const classes = useStyles();
    const history = useHistory();

    const queryString = window.location.search;

    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });

    // extract invitation token from state.
    const states = queryParams.state.split("___");

    const invitationToken = states[1];

    const doExchangeForGoogleToken = async (code) =>{
        try{

            const response = await exchangeForGoogleToken(code);

            if (response.status == 200){
    
                // set local storage of return auth user
                // then we can use the stored token in future api responses.
                localStorage.setItem("user", JSON.stringify(response.data));

                // when states.length is 2, it means it has an invitation token set.
                // this has been set from InvitationRegister.js on this line: await getSignInWithGoogleUriWithInvite(queryParams.invitation_token);
                if (invitationToken.length > 5){
                    // state contains invite token.
                    // accept invite.

                    const inviteResponse = await acceptInvite(invitationToken);
                    
                }


                if (response.data.floors === null  || response.data.floors?.length === 0){
                    // when organisationId is null, google domain user is first time user
                    // redirect
                    history.push('/org-setup');
                    window.location = "/org-setup";
                }
                else if (response.data.teamId === null){
                    // when teamId is null, Google domain user has an existing org, but not team assigned.
                    // redirect
                    history.push('/user-setup');
                    window.location = "/user-setup";
                }
                else {
                    // redirect
                    history.push('/calendar');
                    window.location = "/calendar";
                }
        
            }
        }
        catch(error){
            toast.error(error.message);
        }

    }

    useEffect(()=>{

        if (queryParams.code){
            doExchangeForGoogleToken(queryParams.code);
        }

    },[]);

    function loading(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonProgress} /> {buttonLabel}</span>);
       
    }

    return(
        <div className="mt-16">

            <div className="w-full max-w-md lg:border p-5 mx-auto"> 


                <h1 className="text-2xl text-gray-800 font-semibold mb-6 text-center">Log in</h1>


                <div className="mx-auto text-center">

                    {loading('Just a sec...')}

                </div>


            </div>

           


        </div>
    

    );
}

export default GoogleLoginRedirect;