
const axios = require('axios');

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function getLocalUser() {
    var userJson = localStorage.getItem("user");
    var user = JSON.parse(userJson);
    return user;
}


// Step 1 - Get Google Auth url
export async function getSignInWithGoogleUri(){

    const url = baseUrl+'user/signin-google';

    return axios.get(url);
}

// Step 2 - Once user has authenticated, exchange the code for a token
export async function exchangeForGoogleToken(code){

    const url = baseUrl+'user/google-exchange';

    return axios.post(url,{
        code: code,
    });
}

// Step 1.a - Get Google Auth url with invitation token
export async function getSignInWithGoogleUriWithInvite(invitationToken){

    const url = baseUrl+'user/signin-google?invitation_token='+invitationToken;

    return axios.get(url);
}


// Steps for Google Cal Sync Permissions

// Step 1 - Get Google Cal consent URL - similar to Google login with additional Google Cal scope.
export async function getGoogleCalConsentUri(){

    const url = baseUrl+'auth/calender';

    return axios.get(url);
}


// Step 2 - Once user has authenticated, exchange the code for a token
export async function exchangeForGoogleCalToken(code){

    const url = baseUrl+'auth/google-cal-exchange';

    const user = getLocalUser();

    return axios.post(url,{
        code: code,
    },
    { headers: {'Authorization': `Bearer ${user.token}`}});
}

export async function registerWithEmail({fullName, email, password, optIn}){

    const url = baseUrl + 'user/register';

    return axios.post(url, {
        fullName: fullName,
        email: email,
        password: password,
        optIn: optIn
    });

}

export async function signInWithEmail({email, password}){

    const url = baseUrl + 'user/signin';

    return axios.post(url, {
        email: email,
        password: password,
    });

}

export async function signInWithGoogle(tokenId, accessToken){

    const url = baseUrl+'user/google-auth';

    return axios.post(url,{
        idToken: tokenId,
        accessToken: accessToken
    });
}

export async function getUserDetails(){
    const url = baseUrl + `user/details`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function getSettings(){

    const url = baseUrl + `settings`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function updateSettings({capacity}){
    const url = baseUrl + `settings`;

    const user = getLocalUser();

    return axios({
        method: 'post',
        url: url,
        data: {
            maxCapacity: capacity
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}


export async function getWeeklyAnalytics(year, weekNumber){

    const url = baseUrl + `analytics/week/${year}/${weekNumber}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
     
}

export async function getMonthlyAnalytics(year, month, day){

    const url = baseUrl + `analytics/month/${year}/${month}/${day}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
     
}

export async function getMonthlyAnalyticsNew(year, month, day){

    const url = baseUrl + `analytics?year=${year}&month=${month}&day=${day}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
     
}

