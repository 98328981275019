import React, { useState } from "react";
import UserSuggestionItem from "./UserSuggestionItem";
import LoaderLabel from "./LoaderLabel";

function UserSuggestion({users, onAddUserClick }){

    const [isSearching, setIsSearching] = useState(false);
    const [suggestedUsers, setSuggestedUsers] = useState([]);

    // https://www.youtube.com/watch?v=Q2aky3eeO40
    const onChangeHandler = (event) =>{

        // set isSearching to true to show the spinner
        setIsSearching(true);

        const text = event.target.value;

        let matches = [];

        if (text.length >= 3){
            
            matches = users.filter(x=> {
                const regex = new RegExp(`${text}`, "gi");
                return x.name.match(regex);
            });

            setSuggestedUsers(matches);
        }
        
    }

    const onKeyUpHandler = (event) =>{
        
        // // Detect when typing has finished and then setIsSearching to false
        // Can use this function to call server
        // https://schier.co/blog/wait-for-user-to-stop-typing-using-javascript
        // https://reactgo.com/settimeout-in-react-hooks/
        
        const timeout = setTimeout(() => {
            setIsSearching(false);
            // todo: Can call server to get list of users
          }, 250);
      
        return () => clearTimeout(timeout);

    }

    const onAddUserClickHandler = (user) => {
        onAddUserClick(user);
    }

    const renderUser = (item, index) =>{
        return (
            <UserSuggestionItem user={item} key={index} onAddUserClick={onAddUserClickHandler}/>
        )
    }

    return(
        <div>

            <div className="relative">
                <div className="absolute top-0 bottom-0 left-0 flex items-center px-5">

                    {/* if searching, show spinner, otherwise show magnify-glass icon */}
                    { isSearching ? <LoaderLabel darkMode={true} isLoading={true}   />
                    :  
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    }
                    

                </div>
                <input onChange={onChangeHandler} onKeyUp={onKeyUpHandler} type="text" placeholder="Search for people..." className="pl-16 pr-4 py-3  rounded-md bg-white border w-full outline-none" />
            </div>
            
        
            { suggestedUsers.length > 0 ?
            <div className="search-results-container mt-3 border px-6 rounded">
                <h4 className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Search Results</h4>
                <div className="grid grid-cols-1 text-sm">
                    {suggestedUsers.map(renderUser)}
                </div>
            </div>
            : null }

        </div>
    )
}

export default UserSuggestion;