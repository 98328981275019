import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;


export async function reserve(date, floorId, checkversion){

    const url = baseUrl + `bookings`;

    const user = getLocalUser();

    return axios.post(url,
        {
            date: date,
            floorId: floorId,
            checkversion: checkversion
        },
        {
            headers: {'Authorization': `Bearer ${user.token}`}
        }
    );
}

export async function unreserve(id){

    const url = baseUrl + `bookings/${id}`;

    const user = getLocalUser();

    return axios.delete(url,
        {
            headers: {'Authorization': `Bearer ${user.token}`}
        }
    );
}