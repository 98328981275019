import React from "react";
import Navbar from "../components/Navbar";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import SettingsProfile from "../components/SetingsProfile";


function Settings(){

    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">

                    <div className="side-nav-container px-3">
                        <SideNav />
                    </div>
                    
                    <div className="settings-content-container px-6">
                        <SettingsProfile />
                    </div>
                </div>

               

            </div>

        </div>
    )
}

export default Settings