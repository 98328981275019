import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import FloorList from "../components/FloorList";
import OrganisationForm from "../components/OrganisationForm";
import { getOrganisation, putOrganisation } from "../Api/Organisation";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteFloor } from "../Api/Floors"; 
import LoaderLabel from "../components/LoaderLabel";

function Floors(){

    const [organisationName, setOrganisationName] = useState("");
    const [organisationId, setOrganisationId] = useState("");
    const [fakeRefresh, setFakeRefresh] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const fetchOrganisation = async() => {

        try{
            const response = await getOrganisation();


            setOrganisationName(response.data.name);
            setOrganisationId(response.data.id);
        }
        catch(error){
            toast.error(error.message);
        }

        setIsLoading(false);
    }
    
    useEffect(() => {
        
        fetchOrganisation();

        console.log('Floors useEffect ')

    }, []) 
    // need to have fakeRefresh as useEffect array param cos every time a user is added or removed,
    // the users need to be refreshed in case it needs to be added back in.

    const saveOrganisation = async () =>{

        try{
            const response = await putOrganisation({ 
                organisationId : organisationId,
                name: organisationName
                
            });

            toast.success('Changes saved.')
        }
        catch(error){
            toast.error(error.message);
        }

    }

    const onDeleteFloorHandler = async (floorToDelete) => {

        try{

            await deleteFloor({floorId: floorToDelete.id});

            toast.success('Floor removed.');

            // update react state to force useEffect to refresh
            setFakeRefresh(1);
        }
        catch(error){
            toast.error(error.message);
        }
        
    }

    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">
                    
                    <div className="settings-content-container px-6 w-full">

                        <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Organisation</h3>

                        <OrganisationForm name={organisationName} onNameChange={setOrganisationName} onSave={saveOrganisation} />

                        <div className="flex mt-11 mb-4 border-b pb-4">

                            <div className="title-area flex-grow">
                                <h3 className="text-2xl font-semibold leading-8">Floors</h3>
                            </div>

                            
                            <div className="action-area self-center">
                                <a className="bg-indigo-700 hover:bg-indigo-800 text-white p-2 rounded" href="/organisation/floors/add">Add floor</a>
                            </div>
                        </div>
                        

                        <p class="text-sm text-gray-600">If your organisation spans across multiple floors. Feel free to use the "Add floor" button to add additional floors and capacities.</p>

                        <div className="flex justify-center">
                            <LoaderLabel darkMode={true} isLoading={isLoading} label={''} loadingLabel={'Loading...'} />
                        </div>

                        
                        <FloorList onDeleteFloor={onDeleteFloorHandler} forceRefresh={fakeRefresh} />

                    </div>
                </div>

               

            </div>

        </div>
    )
}

export default Floors