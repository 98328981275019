import React, {useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import FloorForm from "../components/FloorForm";
import { putFloors, getFloor } from "../Api/Floors";
import { getTeams, deleteTeam } from "../Api/Teams";
import { toast } from 'react-toastify';
import TeamList from "../components/TeamList";
import _ from "lodash";

function FloorEdit(){

    const { floorId } = useParams(); // get floorId from url

    const [name, setName] = useState("");
    const [capacity, setCapacity] = useState(0);
    const [teams, setTeams] = useState([]);
    

    const fetchFloor = async() => {

        const response = await getFloor({floorId});
    
        setName(response.data.name);
        setCapacity(response.data.capacity);
    }

    const fetchTeams = async() =>{

        const response = await getTeams({floorId});

        setTeams(response.data);
    }

    useEffect(() => {

        fetchFloor();
        fetchTeams();

    }, [])


    const saveFloor = async () => {

        try{
            const response = await putFloors({
                floorId: floorId,
                capacity: parseInt(capacity),
                name: name
            });

            toast.success('Changes saved.')
        }
        catch(error){
            toast.error(error.message);
        }
        
    }

    const onDeleteTeamHandler = async (teamToDelete) =>{
        
        try{

            // update state
            const updateTeams =_.remove(teams, (team) => { 
                return team.id != teamToDelete.id
            });
            setTeams(updateTeams);

            // call backend
            await deleteTeam({teamId: teamToDelete.id});

            toast.success('Team deleted.')
        }
        catch(error){
            toast.error(error.message);
        }

    }
    
    const renderEmptyTeams = () =>{
        return(
            <div>
                <p className="text-sm text-center bg-gray-200 rounded p-3 border border-gray-300 text-gray-600">
                    You can add teams here. Go ahead and click on the "Add team" button above ☝️
                </p>
            </div>
        )
    }


    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">
                    
                    <div className="settings-content-container px-6 w-full mb-10">
                        <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Floor</h3>
                        <FloorForm
                                name={name}
                                capacity={capacity}
                                onNameChange={setName} 
                                onCapacityChange={setCapacity} 
                                onSave={saveFloor} />

                        <div className="flex mt-11 mb-4 border-b pb-4">
                            <div className="title-area flex-grow">
                                <h3 className="text-2xl font-semibold leading-8"> 

                                    {teams.length > 1 ? teams.length + " Teams" : "Team" }
                                </h3>
                            </div>

                            <div className="action-area self-center">
                                <a className="bg-indigo-700 hover:bg-indigo-800 text-white p-2 rounded" href={`/teams/add?floorId=${floorId}`}>Add team</a>
                            </div>
                        </div>
                        
                        { teams.length > 0
                        ?  
                            <TeamList teams={teams} onDeleteTeam={onDeleteTeamHandler} />
                        :   renderEmptyTeams()
                        }
                       

                    </div>
                </div>

               

            </div>

        </div>
    )

    
}

export default FloorEdit;