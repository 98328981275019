import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;


export async function getOrganisation(){
    const url = `${baseUrl}organisations`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function putOrganisation({organisationId, name}){

    const url = `${baseUrl}organisations`;

    const user = getLocalUser();

    return axios({
        method: 'put',
        url: url,
        data: {
            id: organisationId,
            name: name,
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}