import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { getFloors } from "../Api/Floors";
import { getTeams } from "../Api/Teams";
import { putUser } from "../Api/User";
import _ from "lodash";
import { toast } from "react-toastify";

const FirstSetupUser = () => {

    const [floors, setFloors] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState({});
    const [selectedTeamId, setSelectedTeamId] = useState({});

    const history = useHistory();

    const fetchFloor = async() => {

        try{ 
            const response = await getFloors();
        
            // this is not great, but loop thru floors and get teams for each floor.
            //https://stackoverflow.com/questions/47065444/lodash-is-it-possible-to-use-map-with-async-functions
            const floorsWithTeams = await Promise.all(response.data.map(async(f) =>{

                const teamsInFloor = await fetchTeams(f);

                return {
                    ...f,
                    teams: teamsInFloor
                }
            }));

            // console.log(floorsWithTeams);

            // now filter floors that have teams
            // we do not want a user to select a floor, then no teams to select from the dropdown select
            const validFloors = floorsWithTeams.filter((f)=>{
                return f.teams.length > 0;
            });


            setFloors(validFloors);

        }
        catch(error){
            toast.error(error.message);
        }
    }

    const fetchTeams = async(floor) =>{

        const response = await getTeams({floorId: floor.id});

        return response.data;
        
    }

    useEffect(()=>{

        fetchFloor();

    },[])


    const renderFloorOptions = (item, index) =>{
        return (
            <option key={index} value={item.id}>{item.name}</option>
        )
    }

    const renderTeamOptions = (item, index) =>{
        return (
            <option key={index} value={item.id}>{item.name}</option>
        )
    }

    const onFloorOptionChanged = (event) => {
        console.log(event.target.value);

        const floorFound = _.find(floors, (f) =>{
            return f.id == event.target.value
        });

        console.log(floorFound);

        setSelectedFloor(floorFound);
    }

    const onTeamOptionChanged = (event) =>{

        console.log(event.target.value);

        setSelectedTeamId(event.target.value);


    }

    const onSetupUserTeamClickHandler = async() => {

        try{
            // assign floor and team for user.
            await putUser({
                data: { teamId: selectedTeamId }
            });

            // redirect
            history.push('/calendar');
            window.location = '/calendar';
        }
        catch(error){
            toast.error(error.message);
        }
        
    }

    const handleSubmit = (event) =>{ 

        event.preventDefault();

     
        if (selectedTeamId && selectedTeamId.length > 0){
            onSetupUserTeamClickHandler();
        }

        
    
    }

    return (


        <div className="flex justify-center mt-24">

            <Helmet>
                <title>OfficeMixer</title>
            </Helmet>

            <div className="border-gray-500 border rounded-md max-w-md w-full p-4">

                <div className="">

                    <h1 className="text-2xl text-gray-800 font-semibold leading-10 mb-5">Setup your profile</h1>
                    <div>
                        <form onSubmit={handleSubmit}>

                            <div className="grid grid-cols-6 gap-6"> 
                                <div className="col-span-6">
                                    <label htmlFor="floorOption" className="block text-sm font-medium text-gray-700">Select your floor</label>
                                    
                                    <select id="floorOption" 
                                        value={ selectedFloor?.id}
                                        onChange={onFloorOptionChanged}
                                        className="border border-gray-300 block w-full mt-1 rounded py-3 px-3">
                                        <option>-- Select --</option>
                                        {floors.map(renderFloorOptions)}
                                    </select>

                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="teamOption" className="block text-sm font-medium text-gray-700">Select your team</label>
                                    
                                    <select id="teamOption" 
                                        onChange={onTeamOptionChanged}
                                        className="border border-gray-300 block w-full mt-1 rounded py-3 px-3">
                                        <option>-- Select --</option>

                                        { selectedFloor.teams && selectedFloor.teams.length > 0 ? selectedFloor.teams.map(renderTeamOptions) : null }
                                    </select>

                                </div>

                            </div>

                            <div className="mt-8">
                                <button type="submit"
                                    className="bg-indigo-700 hover:bg-indigo-800 text-white p-2 rounded w-full flex justify-center">
                                    <span>Setup and continue</span>
                                    <span className="ml-1 self-center">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0378 6.34317L13.6269 7.76069L16.8972 11.0157L3.29211 11.0293L3.29413 13.0293L16.8619 13.0157L13.6467 16.2459L15.0643 17.6568L20.7079 11.9868L15.0378 6.34317Z" fill="currentColor" /></svg>
                                    </span>
                                </button>
                            </div>

                        </form>
                    </div>

                </div>

            </div>

        </div>
    )



}


export default FirstSetupUser;