import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;


export async function acceptInvite(token){

    const url = baseUrl + `invitations`;

    const user = getLocalUser();

    return axios({
        method: 'put',
        url: url,
        data: {
            invitationToken: token,
            status: "accept"
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function getInvitations(){
    const url = baseUrl + `invitations`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function createInvite(recipientEmail){

    const url = baseUrl + `invitations`;

    const user = getLocalUser();

    return axios({
        method: 'post',
        url: url,
        data: {
            recipientEmail: recipientEmail,
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}