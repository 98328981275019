import React, {useState, useEffect, useRef} from "react"
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'white',
    },
    buttonProgressDark: {
      color: '#2d3748'
    }
  }));

function BookableDay({bookingDay, index, onShowBookingsClicked, onBookClicked, onUnbookClicked, filteredTeams}) {

    const classes = useStyles();

    async function onReserveClicked(e){

        e.preventDefault();

        const target = e.currentTarget;

        e.persist();

        // mark button as disabled to prevent double submits.
        target.setAttribute("disabled", "disabled");

        console.log("before");

        await onBookClicked(bookingDay);

        console.log("after");
        // mark button as enabled
        target.removeAttribute("disabled");
    
    }

    async function onUnreserveClicked(e){
        
        e.preventDefault();

        const target = e.currentTarget;

        e.persist();

        // mark button as disabled to prevent double submits.
        target.setAttribute("disabled", "disabled");

        console.log("before");

        await onUnbookClicked(bookingDay);

        // mark button as enabled
        target.removeAttribute("disabled");
    }

     function onShowBookings(e){

        e.preventDefault();
    
        e.persist();

        onShowBookingsClicked(bookingDay, index);
    
      }

    function loading(label, size){
       
        return(<span className=""><CircularProgress size={size} className={classes.buttonProgress} /> {label}</span>);
       
      }


      const renderPerson = (booking, index) => {
        return(
          <div className="person-card" key={index}>
            <div className="flex flex-row" >
              { booking.bookedBy.hasPictureProfile.length > 0 ?
    
                <img className="rounded-full bg-blue-200 w-8 h-8 flex items-center justify-center" alt="pic" src={booking.pictureProfile} />
                :
                <div className={booking.bookedBy.bgColor + " rounded-full w-8 h-8 flex items-center justify-center"}>
                  <span className={booking.bookedBy.textColor + " text-sm font-semibold"}>{booking.bookedBy.initial}</span>
                </div>
              }
    
              <div className="ml-2 flex items-center justify-center">
                <div className="flex flex-col">
                  <div className="text-sm font-semibold">
                    {booking.bookedBy.fullName}
                  </div>
                  <div className="text-xs leading-5">
                    {booking.bookedBy.teamName}
                  </div>
                </div>
              </div>
            </div>


        
          </div>
        );
      }


      const renderFilteredPerson = (booking, index) => {

        // when filteredTeams length is zero, filters are not enabled
        if (filteredTeams.length  === 0) {

          return renderPerson(booking, index);
        }

        else {
          
          // if user appears in filteredTeams list then, render it.
          const result = filteredTeams.find((t) => {
            return t.id === booking.bookedBy.teamId;              
          });

          if (result){
            return renderPerson(booking, index);
          }
          
        }
        
    
      }


        return(
          <div className="date-row border-t border-gray-200 mb-5 p-3" key={index}>
                 
              <div className="date-row-content flex flex-row">
    
                { bookingDay.isToday ? 
                  <div className="today-indicator flex self-center tooltip " 
                    aria-label="Today!" data-microtip-position="top" role="tooltip">
                    <div className="rounded-full w-2 h-2 bg-indigo-500"></div>
                  </div>
                : null
                }
                
                <div className="date-part w-16 text-center">
    
                  <div className="font-semibold text-gray-800">{bookingDay.dayOfWeek}</div>
                  <div className="text-2xl font-bold leading-10 text-gray-800">{bookingDay.numerical}</div>
    
                </div>
    
                <div className="statbox flex justify-center flex-grow font-semibold">
    
                  <div className="info-part w-16 pr-2 md:w-32 flex ">
    
                    <div className="self-center">
    
                      { bookingDay.capacityAvailable === 0 ?  

                        <div className="text-xl leading-8">Full</div>   
                        : 
                        <div className="text-gray-700">
                          <div className="text-xl leading-8">{bookingDay.capacityAvailable} </div>
                          <div className="uppercase text-xs text-gray-600">Spaces left</div>
                        </div>
                      } 
                    </div>
    
                  </div>
    
                  <div className="people-info w-16 md:w-32 self-center">
    
                    <div className="text-gray-700">
                      <div
                          className="trigger-btn focus:outline-none cursor-pointer hover:text-gray-800" 
                          onClick={onShowBookings} 
                          id={bookingDay.numerical}>

                          <div className="flex">
                            <div className="">
                              <div className="text-xl leading-8">{bookingDay.peopleCount}</div>
                              <div className="uppercase text-xs text-gray-600">Attendees</div>
                            </div>
                            <div className="indicator-chevron self-end">
                              {bookingDay.showBookings ?
                                <img className="h-5 w-5 pl-1" src="/images/cheveron-up.svg" alt="up" />
                                :
                                <img className="h-5 w-5 pl-1" src="/images/cheveron-down.svg" alt="down" />
                              }
                            </div>
                          </div>
                      </div>
                    </div>
    
                  </div>
    
                </div>
                
                
                <div className="self-center bookingDays-center">
                  {
                    bookingDay.isBookedByUser ?
                    <button 
                      id={bookingDay.numerical}
                      onClick={onUnreserveClicked}
                      className="border-indigo-500 border-2 hover:bg-indigo-500 hover:text-white text-indigo-500 text-font-semibold px-1 py-1 rounded text-sm font-semibold">
                        Unbook</button>
                    :
                    null
                  }
    
                  {
                    bookingDay.isBookedByUser === false && bookingDay.capacityAvailable > 0?
                    <button 
                    id={bookingDay.numerical}
                    onClick={onReserveClicked}
                    className="bg-indigo-500 hover:bg-indigo-700 text-white text-font-semibold px-3 py-1 rounded text-sm font-semibold">
                    { bookingDay.isLoading ? loading('', 13) : null } Book</button>
                    : null
                  }
    
                  {
                    bookingDay.isBookedByUser === false && bookingDay.capacityAvailable === 0 ?
                    <div className="bg-gray-300 rounded px-3 py-1 text-sm font-semibold">
                      Full
                    </div>
                    : null
                  }
    
                  {/* {
                    enableBookBtn(bookingDay) == false ? <button> expired </button> : null
                  } */}
    
                </div>
    
              </div>
    
              <div className="ml-2">
              { bookingDay.isBookedByUser ?
                      <div>
                        <span className="text-xs rounded bg-gray-300 px-2 py-1 text-gray-800 font-semibold">Booked</span>
                      </div>
                      : null
                    }
              </div>
    
              <div className={ bookingDay.showBookings ? 'people-list p-3 mt-3' : ' people-list p-3 mt-3 hidden'} >
    
                  <h2 className=" text-gray-700 uppercase font-semibold text-sm leading-8 my-2">People</h2>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
                    {bookingDay.bookings.map(renderFilteredPerson)}
                  </div>
                  
              </div>
    
    
          </div>
        )
      

}

export default BookableDay