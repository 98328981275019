import React, {useState} from "react";
import LoaderLabel from "./LoaderLabel";


function UserSuggestionItem({user, onAddUserClick}){

    const [isLoading, setIsLoading] = useState(false);

    const onClickHandler = (event, item) =>{
        event.preventDefault();

        setIsLoading(true);

        onAddUserClick(item);

        setIsLoading(false);
    }

    const renderAddToTeam = () =>{
        return (
            <button onClick={(e) => onClickHandler(e, user)} 
                    className="focus:outline-none border border-gray-500 px-2 py-2 rounded hover:bg-gray-800 hover:text-white"
                    title="Add this user to the team">
                <LoaderLabel darkMode={true} isLoading={isLoading} label="Add user to team" loadingLabel="Adding user..." />
            </button>
        )
    }

    const renderAlreadyAssignedToATeam = () =>{
        return(
            <div className="text-gray-600">
                Already assigned to {user.teamName}
            </div>
        )
    }

    return(
        <div className="py-3 border-b flex">
            
            <div className="flex-grow">
                {user.name}
            </div>
            <div className="">

            { user.teamName.length  === 0 ? renderAddToTeam() : renderAlreadyAssignedToATeam() }
                


            </div>
        </div>
    )

}

export default UserSuggestionItem;