
import React, { isValidElement, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { getInvitations, createInvite } from '../Api/Invitations';
import { updateUserRole } from '../Api/User';
import { getMembers } from '../Api/Members';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { getLocalUser } from '../utils/Utils';
import jwtDecode from 'jwt-decode';


// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonDarkProgress: {
        color: 'black',
      },
      buttonLightProgress:{
          color: 'white'
      }

  }));

toast.configure();

function MemberInvite(){

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [members, setMembers] = useState([]);
    const [membersLoaded, setMembersLoaded] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const [memberCount, setMemberCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [enableInviteBtn, setEnableInviteBtn] = useState(false);

    const localUser = getLocalUser();

    const decodedJwt = jwtDecode(localUser.token);


    useEffect(()=>{

        const getInvitationsOperation = async() => {

            try{
                
                const response = await getInvitations();

                if (response.status == 200){
                    setInvitations(response.data);
                }

            }
            catch(error){
                toast.error(error.message);
            }
        }

        const getMembersOperation = async() => {

            try{
                const response = await getMembers();

                if (response.status == 200){
                    setMembers(response.data);
                    setMembersLoaded(true);
                }
            }
            catch(error){
                toast.error(error.message);
            }
        }

        getInvitationsOperation();
        getMembersOperation();


    },[memberCount])


    function handleChange(event){
        const {name, value, type, checked} = event.target;

        switch(name){
            case "email":
                setEmail(value);
                isValidEmail(value);
                break;
            default:
        }
    }

    function isValidEmail(email){
        const emailRegex = new RegExp('^(?!(?:[^@]*\@){2}).*[@].*\.[a-zA-Z]{2,}$');

        const isValid = emailRegex.test(email);

        return isValid;
    }

    async function handSendInvite(event){

        event.preventDefault();

        setIsLoading(true);

        try{

            const response = await createInvite(email);

            if (response.status === 200){
                
                toast.success('Invite sent! ✉️');
                // update a client sound counter so that it will call useEffect to update the list of members.
                // it will update as it as the 'memberCount' as a param in the array
                setMemberCount( memberCount + 1);

            }

        }
        catch(error){


        }


        setIsLoading(false);

    }

    const renderInvitationRow = (item, index) => {

        let statusBadge;

        if (item.status === 'accepted'){
            statusBadge = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Active
                </span>
            )
        }
        else if (item.status === 'pending'){
            statusBadge = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Pending
                </span>
            )
        }

        return (
            <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                 {item.recipientEmail}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    
                    { statusBadge }

                </td>
            </tr>
        )
    }

    const renderMemberRow = (item, index) => {


        function onRoleChange(event){
            const target = event.target;
            console.log('changed', target.value, item);

            
            updateUserRole({role: target.value, userIdToUpdate: item.id})
                .then((response)=>{
                    toast.success('Role updated.');
                    setMemberCount( memberCount + 1);
                    
                })
                .catch((error)=>{
                    toast.error('Hmm..something went wrong.');
                    
                });
            
 
        }

        function getDefaultOptionValue(){

            if (item.isAdmin === true){
                return 'admin';
            }
            return 'user';
        }

        // Return this simple view if the current user is also the same member in this list.
        if (localUser.id === item.id){
            return (
                <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {item.fullName}
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            It's you
                        </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {item.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm" > 
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                        {item.isAdmin ? 'Admin' : 'User'}
                        </span>
                    </td>
                </tr>
            )
        }
        // if the current user is an admin, allow them permission to change other peoples roles.
        if (decodedJwt.role === 'admin'){
            // return member view
            return (
                <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {item.fullName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {item.email}
                    </td>
                    <td>
                
                        <select className="block w-full mt-1 rounded-md border-gray-300 border text-sm py-2 px-1"
                            onChange={onRoleChange}
                            value={getDefaultOptionValue()}
                            >
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    
                    </td>
                </tr>
            )
        }

        // default read-only view
        return (
            <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {item.fullName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {item.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm" > 
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                        {item.isAdmin ? 'Admin' : 'User'}
                    </span>
                </td>
            </tr>
        )
    }

    function loadingDark(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonDarkProgress} /> {buttonLabel}</span>);
       
    }

    function loadingLight(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonLightProgress} /> {buttonLabel}</span>);
       
    }

    return(
        <div>
            
            <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Members</h3>

            {/* Invitations section */}
            <div className="">

                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                        Invite Members
                    </h3>

                    <p className="my-5">You can send an invite to members to join your team.</p>

                    <div className="invitation-panel mt-5">
                        
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input 
                                className="appearance-none block w-full py-3 px-4 
                                leading-tight text-gray-700 focus:bg-white border border-gray-300 
                                focus:border-gray-500 rounded focus:outline-none"
                                type="text" 
                                name="email"
                                placeholder="Email"
                                onChange={handleChange}
                                required
                                />
                            
                        </div>
                        <div className="flex flex-row justify-end">
                            <button 
                                type="submit" 
                                disabled={enableInviteBtn}
                                className="disabled:opacity-50 inline-flex justify-center py-2 px-2 shadow-sm text-sm font-medium bg-indigo-700 text-white rounded-md"
                                onClick={handSendInvite}>
                                
                                { isLoading ? loadingLight('Inviting') : 'Invite'}
                            </button>
                        </div>
                    </div>

                    {invitations.length > 0 ?
                    <div>
                        <h4>Invitations</h4>
                        <div className="overflow-x-auto">
                            <div className="py-2 align-middle inline-block min-w-full">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {invitations.map(renderInvitationRow)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null }
                    
                </div>

            </div>

            {/* Members section */}
            <div className="">

                <div className="px-4 py-5 sm:px-6 ">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                        Members
                    </h3>

                    <div className="my-4"> 
                        <p className="bg-blue-200 text-blue-700 px-2 py-1 rounded text-sm border-blue-400 border">
                        <span className="font-semibold">Note: </span>Updating roles take affect when users re-login.
                        </p>
                    </div>

                    <div className="mt-5 overflow-x-auto">
                        
                        { members.length > 0 ?
                        <div className="py-2 overflow-hidden align-middle inline-block min-w-full">
                            <div className="shadow border-b border-gray-200 sm:rounded-lg">
                        
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Email
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Role
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {members.map(renderMemberRow)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : loadingDark('') }

                        { members.length == 0 && membersLoaded ?
                        <div>
                            <p className="text-sm text-center bg-gray-200 rounded p-3 border border-gray-300 text-gray-600">
                                Your team members will appear here. <br/> Use the invitation button above ☝️ to invite other team members.
                            </p>
                        </div>: null} 
                    </div>

                </div>

            </div>
            

        </div>
    )

}

export default MemberInvite