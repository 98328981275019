
import React, {useState, useEffect, useRef} from "react"
import microtip from 'microtip/microtip.css'
import { getBookingDays, getBookingDay } from "../Api/BookingDays";
import { getUser } from "../Api/User";
import {reserve, unreserve} from "../Api/Bookings";
import { getFloors } from "../Api/Floors";
import { getTeams } from "../Api/Teams";
import _ from 'lodash';
import moment from 'moment';
import HomeNavbar from "../components/Navbar"
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import BookingDay from "../components/BookableDay";
import TeamFilter from "../components/TeamFilter";
import DropdownListbox from "../components/DropdownListbox";

// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

  buttonProgress: {
    color: 'white',
  },
  buttonProgressDark: {
    color: '#2d3748'
  }
}));

toast.configure();

function Dashboard(){

    const classes = useStyles();

    const weekNumber = moment().isoWeek();
    const theYear = moment().year();

    // const dt = DateTime.now();

    const [days, setDays] = useState([]);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    const [userDetails, setUserDetails] = useState({});

    const [currentWeekNumber, setCurrentWeekNumber] = useState(weekNumber);
    

    const [selectedFloor, setSelectedFloor] = useState({});
    const [floors, setFloors] = useState([]);
    const [teams, setTeams] = useState([]);
    
    // array of teamIds populated to only show specific teams based on team filter
    const [filteredTeams, setFilteredTeams] = useState([]);

    // this fake state is used to refresh useEffect when the floor dropdown list is changed.
    const [fakeRefresh, setFakeRefresh] = useState(0);

    const fetchBookingDays = async (selectedFloorId) =>{

      try {
        const response = await getBookingDays(theYear, weekNumber, selectedFloorId);

        setDays(response.data.days);
        setMonth(response.data.month);
        setYear(response.data.year);
      }
      catch (error){
        console.log(error);
        toast.error(error.message);
      }

    }

    const fetchUserDetails = async () => {
      try{
          const response = await getUser();
          setUserDetails(response.data);

      }
      catch(error){
        toast.error(error.message);
      }
    }

    const fetchTeams = async (floor) =>{
      // get teams associated to floor
      const teamsResponse = await getTeams({floorId: floor.id});

      // map the teams and add a "enabled" field to be used for toggling filter on/off
      const mappedTeams = teamsResponse.data.map((team)=>{
      
        return {
            ...team,
            enabled: false // toggle used for on/off of filter
        }
        
      });

      setTeams(mappedTeams);

      fetchBookingDays(floor.id);
    }

    const fetchFloors = async () =>{

      try{

        const response = await getFloors();

        const mappedFloors = response.data.map((f) =>{
          return {
            ...f,
            name: `${f.name} (${f.capacity})`,
            selected: false,
          }
        });

        setFloors(mappedFloors);

        // check if selectedFloor is null. If so, that means its the first page load.
        const selectedFloorId = _.get(selectedFloor, 'id');
        
        if (_.isUndefined(selectedFloorId)){

          // get the first floor from list
          const topFloor = _.first(response.data);

          setSelectedFloor(topFloor);

          // now fetch teams based on default floor.
          fetchTeams(topFloor);
        }
        else {
        
          // now fetch teams based on selected floor - this is triggered from onFloorChanged
          fetchTeams(selectedFloor);
        }
        
       

      }
      catch(error){
        toast.error(error.message);
      }

    }

    const onFloorChanged = (floorSelected) =>{
      // floor changed form listbox
      setSelectedFloor(floorSelected);

      // use this fake refresh state to call useEffect again.
      setFakeRefresh( (value) => value+1);
    }



    useEffect(()=>{

      fetchFloors();
    
      fetchUserDetails();  

    },[fakeRefresh]);

    async function bookClicked(bookingDay){

      // show loading spinner for specific button
      const updatedDaysForSpinner = days.map((day)=>{

        if (day.numerical.toString() === bookingDay.numerical.toString()){
        
            day.isLoading = true;
            
        }
        return day;

      });

      // update react state to show spinner.
        
      setDays(updatedDaysForSpinner);

      try {
        // mark day as booked
        await reserve(bookingDay.date, selectedFloor.id, bookingDay.checkVersion);
        
        // get latest bookings
        const response = await getBookingDay(bookingDay.date, selectedFloor.id);
    
        // update React state with latest bookings.
        const updatedDays = days.map((day)=>{
  
          if (day.numerical.toString() === bookingDay.numerical.toString()){

              // day.showBookings = !day.showBookings;
              day.bookings =  response.data.bookings.map(x=>{return x});
              day.isBookedByUser = response.data.isBookedByUser;
              day.bookingId = response.data.bookingId;
              day.peopleCount = response.data.peopleCount;
              day.capacityAvailable = response.data.capacityAvailable;
              day.checkVersion = response.data.checkVersion;
              day.isLoading = false;
          }
          return day;
  
        });
    
        setDays(updatedDays);

        console.log("after event");
      }
      catch(error){
        // stop showing the loading spinner for specific button
        const updatedDaysForSpinner = days.map((day)=>{
    
          if (day.numerical.toString() === bookingDay.numerical.toString()){
          
              day.isLoading = false;
              
          }
          return day;

        });
        // update react state to hide spinner.
        setDays(updatedDaysForSpinner);

        if (error.response.status == 400 && error.response.data === "fully_booked"){
          toast.error('😱 Oh no! Looks like that day is fully booked!')
        }
        else {
          console.log(error);
          toast.error(error.message +' You can try again?');
        }
      }
    }

    async function unbookClicked(bookingDay){

      // show loading spinner for specific button
      const updatedDaysForSpinner = days.map((day)=>{
  
        if (day.numerical.toString() === bookingDay.numerical.toString()){
        
            day.isLoading = true;
            
        }
        return day;

      });
      // update react state to show spinner.
      setDays(updatedDaysForSpinner);

      try{
        // mark as unreserved
        await unreserve(bookingDay.bookingId);

        const response = await getBookingDay(bookingDay.date, selectedFloor.id);

        const updatedDays = days.map((day)=>{

          if (day.numerical.toString() === bookingDay.numerical.toString()){
            
              day.bookings =  response.data.bookings.map(x=>{return x});
              day.isBookedByUser = response.data.isBookedByUser;
              day.bookingId = response.data.bookingId;
              day.peopleCount = response.data.peopleCount;
              day.capacityAvailable = response.data.capacityAvailable;
              day.isLoading = false;
          }
          return day;

        });

        setDays(updatedDays);

      }
      catch(error){
        console.log(error);
        toast.error(error.message);
      }
    
    }

    function onTeamFilterToggleHandler(item) {

      // go thru list of teams and toggle the enabled flag.
      const updatedTeams = teams.map((x)=>{
        if (x.id === item.id){
          // toggle enabled flag here
          x.enabled = !item.enabled;
        }
        return x;
      })

      //  update react state of teams
      setTeams(updatedTeams);

      console.table(updatedTeams);

      const teamsWithFilterEnabled = updatedTeams.filter((y) => {
        return y.enabled;
      });

      setFilteredTeams(teamsWithFilterEnabled);
    }

    function enableBookBtn(item){

      // only show book btn if the 'item' is today or in the futurer.
      const itemDay = DateTime.fromISO(item.day);

      if (itemDay.toISODate() < DateTime.now().toISODate()){
        return false;
      }
      return true;
    }



    function loadingDark(label, size){
       
      return(<span className=""><CircularProgress size={size} className={classes.buttonProgressDark} /> {label}</span>);
     
    }


    async function toggleShowBookings(daySelected, updatedIndex){

      const response = await getBookingDay(daySelected.date, selectedFloor.id);

      const updatedDays = days.map((day)=>{

        if (day.numerical.toString() === daySelected.numerical.toString()){
          
            day.showBookings = !day.showBookings;

            day.bookings = response.data.bookings.map(x=>{return x});
        }
        return day;

      });

      setDays(updatedDays);

    }



 const onClickPrevious = async () => {

    try {
      const response = await getBookingDays(theYear, currentWeekNumber - 1, selectedFloor.id);

      setDays(response.data.days);
      setMonth(response.data.month);
      setYear(response.data.year);

      setCurrentWeekNumber(value=>value-1);

    }
    catch(error){
      console.log(error);
    }

  }

  const onClickNext = async () =>{
    try{
      const response = await getBookingDays(theYear, currentWeekNumber + 1, selectedFloor.id);

      setDays(response.data.days);
      setMonth(response.data.month);
      setYear(response.data.year);

      setCurrentWeekNumber(value=>value+1);
    }
    catch(error){
      console.log(error);
    }
  }
  



    return(
      <div>
        <HomeNavbar />
        <div className="mt-8 md:mt-12">
    
            <Helmet>
                <title>OfficeMixer - Dashboard</title>
            </Helmet>  
            
            <div className="max-w-2xl mx-auto rounded-lg p-5 shadow text-gray-700">
                
                <div className="text-center">
                  { days.length <=0 ? loadingDark('Loading...', 15) : null}
                </div>
                              

                <h1 className="text-5xl font-semibold">{month} <span className="text-2xl">{year}</span></h1>

                <div className="flex mt-3">
                  <div className="orgName text-gray-700 font-semibold text-sm self-center flex-grow">{userDetails.organisationName}</div>
                  <div className="w-1/2"> 
                  { floors.length > 0 ? <DropdownListbox dataItems={floors} onSelectedItem={onFloorChanged} /> : null }
                  </div>  
                </div>

                <div className="control-panel my-3 flex">

                  <div className="flex-grow pl-1 self-center">
                    <TeamFilter teams={teams} onTeamFilterToggle={onTeamFilterToggleHandler} />
                  </div>

                  <div className="flex ">

                    <div onClick={onClickPrevious} 
                        aria-label="Previous week" data-microtip-position="top" role="tooltip"
                        className="hover:bg-gray-200 cursor-pointer h-8 w-8 rounded-full flex justify-center tooltip">
                      
                      {/* Left chevron  */}
                      <div className="self-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                      </div>

                    </div>

                    <div onClick={onClickNext}
                      aria-label="Next week" data-microtip-position="top" role="tooltip"
                      className="hover:bg-gray-200 cursor-pointer h-8 w-8 rounded-full flex justify-center tooltip">
                      
                      {/* Right chevron */}
                      <div className="self-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </div>

                    </div>

                  </div>

                </div>
                  
                  { days.map( (item, index) => 
                    <BookingDay 
                      bookingDay={item} 
                      index={index} 
                      key={index}
                      onShowBookingsClicked={toggleShowBookings}
                      onBookClicked={bookClicked}
                      onUnbookClicked={unbookClicked}
                      filteredTeams={filteredTeams}
                      />) 
                  }
                 

            </div>


        </div>
        </div>
    );


}


export default Dashboard