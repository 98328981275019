import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { postFloors } from "../Api/Floors";
import { postTeam } from "../Api/Teams";
import { putUser } from "../Api/User";
import { putOrganisation } from "../Api/Organisation";
import { toast } from 'react-toastify';
import { getLocalUser } from "../utils/Utils"; 
import _ from "lodash";

const FirstSetupOrg = () => {

    const [orgName, setOrgName] = useState('');
    const [capacity, setCapacity] = useState('');

    const history = useHistory();

    const onSetupClickHandler = async () => {

        try{
            
            const { organisationId } = getLocalUser();

            const putOrgResponse = await putOrganisation({organisationId: organisationId, name: orgName});

            console.log(putOrgResponse);

            // create a default floor and default team and set user to team
            const floorResponse = await postFloors({ name: 'Default floor', capacity: parseInt(capacity)});

            console.log(floorResponse)

            const floorId = _.get(floorResponse, 'data.id');
            // create a default team
            const teamResponse = await postTeam({ name: 'Default team', floorId: floorId});

            console.log(teamResponse)

            const teamId = _.get(teamResponse, 'data.id');

            // set user to default team
            const putUserResponse = await putUser({ data: { teamId: teamId }});


            // redirect
            history.push('/calendar');
            window.location = '/calendar';
            

        }
        catch(error){
            console.log(error);
            toast.error(error.message);
        }
    }

    const handleSubmit = (event) =>{

        event.preventDefault();

        if (orgName.length <= 0){

        }


        if (capacity.length <= 0){

        }


        if (orgName.length > 0 && capacity.length > 0)
        {
            onSetupClickHandler();

            
        }

    }


    return (


        <div className="flex justify-center mt-24">

            <Helmet>
                <title>OfficeMixer</title>
            </Helmet>

            <div className="border-gray-500 border rounded-md max-w-md w-full p-4">

                <div className="">

                    <h1 className="text-2xl text-gray-800 font-semibold leading-10 mb-5">Setup your team</h1>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-6 gap-6"> 
                                <div className="col-span-6">
                                    <label htmlFor="organisationName" className="block text-sm font-medium text-gray-700">What's your organisation or company name?</label>
                                    <input type="text" name="organisationName" id="organisationName" placeholder="My organisation" required
                                        value={orgName}
                                        onChange={(e) => setOrgName(e.target.value)}
                                        className="appearance-none block w-full py-2 px-3 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" />
                                </div>

                                <div className="col-span-6">
                                    <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">What capacity do you want to set?</label>
                                    <input type="text" name="capacity" id="capacity" placeholder="35" required
                                        value={capacity}
                                        onChange={(e) => setCapacity(e.target.value)}
                                        className="appearance-none block w-full py-2 px-3 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" />
                                    
                                </div>
                            </div>

                            
                            <div className="mt-8">

                                <div className="my-3 text-center">
                                    <p className="text-sm text-gray-700">Don't worry you can always change these settings later.</p>
                                </div>

                                <button type="submit"
                                        className="bg-indigo-700 hover:bg-indigo-800 text-white p-2 rounded w-full flex justify-center">
                                    <span>Setup and continue</span>
                                    <span className="ml-1 self-center">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0378 6.34317L13.6269 7.76069L16.8972 11.0157L3.29211 11.0293L3.29413 13.0293L16.8619 13.0157L13.6467 16.2459L15.0643 17.6568L20.7079 11.9868L15.0378 6.34317Z" fill="currentColor" /></svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </div>
    )



}


export default FirstSetupOrg;