import React from 'react';
import LoaderLabel from "../components/LoaderLabel";
import UserListButtonItem from "../components/UserListButtonItem";

function UserList({users, usersLoaded, onUserDelete}) {

    

    const onButtonClickHandler = (item) =>{

        onUserDelete(item);

    }



    const renderRow = (user, index) => {

        return (
            <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {user.name}
                </td>
                <td className="text-right pr-4">

                    <UserListButtonItem user={user} onDeleteClick={onButtonClickHandler} />

                </td>
            </tr>
        )
    }


    return(
        <div className="mt-5 overflow-x-auto">
                        
            { users.length > 0 ?
            <div className="py-2 overflow-hidden align-middle inline-block min-w-full">
                <div className=" border-b border-gray-200 sm:rounded-lg">
            
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Name
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {users.map(renderRow)}
                        </tbody>
                    </table>
                </div>
            </div>
            : <LoaderLabel darkMode={true} isLoading={!usersLoaded} /> }

                { users.length == 0 && usersLoaded ?
            <div>
                <p className="text-sm text-center bg-gray-200 rounded p-3 border border-gray-300 text-gray-600">
                    Use the search bar above ☝️ to search for people to add to this team.
                </p>
            </div>: null} 
        </div>
    )

}

export default UserList;