import React, {useState} from "react"
import { useHistory } from 'react-router-dom'
import { getSignInWithGoogleUriWithInvite, registerWithEmail } from "../utils/Api.js";
import { acceptInvite } from "../Api/Invitations";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import qs from "qs";
import 'react-toastify/dist/ReactToastify.css';


// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonDarkProgress: {
      color: 'black',
    },
    buttonLightProgress:{
        color: 'white'
    }
    
  }));

toast.configure();

function InvitationRegister(){

    const classes = useStyles();
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [optIn, setOptIn] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();

    const queryString = window.location.search;

    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    
    async function onGoogleSign(){
        
        setIsGoogleLoginLoading(true);

        try{
            const response = await getSignInWithGoogleUriWithInvite(queryParams.invitation_token);
            history.push(response.data.url);
            window.location = response.data.url;
        }
        catch (error){
            toast.error(error.message);
            setIsGoogleLoginLoading(false);
        }
    }

    const toggleShowHidePassword = (event) => {

        event.preventDefault();

        setShowPassword(!showPassword);
    }

    function handleChange(event){
        const {name, value, type, checked} = event.target;

        switch(name){
            case "email":
                setEmail(value);
                break;
            case "fullName":
                setFullName(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "optIn":
                setOptIn(checked);
                break;
            default:
        }
    }

    async function handleSubmit(event){

        event.preventDefault();

        setIsLoading(true);

        try{
            
            const response = await registerWithEmail({
                fullName: fullName, 
                email: email,
                password: password,
                optIn: optIn
            });


            // Now proceed
            if (response.status == 200){

                // set local storage of return auth user
                // then we can use the stored token in future api responses.
                localStorage.setItem("user", JSON.stringify(response.data));

            
                // accept invite. It must have the invitation token in query string params

                const queryString = window.location.search;

                const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
                
                const inviteResponse = await acceptInvite(queryParams.invitation_token);


                if (inviteResponse.data.floors === null  || inviteResponse.data.floors?.length === 0){
                    // when organisationId is null, google domain user is first time user
                    // redirect
                    history.push('/org-setup');
                    window.location = '/org-setup'
                }
                else if (inviteResponse.data.teamId === null){
                    // when teamId is null, Google domain user has an existing org, but not team assigned.
                    // redirect
                    history.push('/user-setup');
                    window.location = '/user-setup';
                }
                else {
                    // redirect
                    history.push('/calendar');
                    window.location = '/calendar';
                }

            }

            

        }
        catch(error){

            if (error.response?.status === 400 && error.response?.data.errors){
                // validation errors.
                const {Email, Password} = error.response.data.errors;
    
                const errors = [...(Email? Email : []), ...(Password? Password: [])];
                
    
                setValidationErrors(errors);
            }
            else if (error.response?.status === 400 && error.response?.data.state === 'account_exists'){

                toast.error(error.response.data.message);

            }
            else {
                toast.error(error.message);
            }
    
            setIsLoading(false);
        }
    }


    function loadingDark(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonDarkProgress} /> {buttonLabel}</span>);
       
    }

    function loadingLight(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonLightProgress} /> {buttonLabel}</span>);
       
    }

    function showValidationErrors(){
        return(
            validationErrors.length > 0 ?
        <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm my-5" role="alert">
            <span className="block sm:inline">
                <ul className="list-disc px-5">
                {
                    validationErrors.map((value, index)=>{
                        return <li key={index}> {value} </li>
                    })
                }
                </ul>

            </span>
        </div> 
        : null
        )
    }


    return(
        <div className="mt-16">

            <Helmet>
                <title>OfficeMixer - Invitation register</title>
            </Helmet>

            <div className="w-full max-w-md mx-auto flex flex-col items-center"> 

                <img src="/images/officeMixerLogoblueblack.svg" className=" h-16 w-16" />
                <h1 className="w-full mx-auto text-center font-semibold my-6 tracking-tight">
                    OfficeMixer
                </h1>

                <div className="lg:border w-full p-5 rounded mt-8">

                    <h2 className="text-2xl text-gray-800 font-semibold mb-6 text-center">
                    👋 You've been invited to use OfficeMixer</h2>

                    <div className={isGoogleLoginLoading ? " hidden mx-auto" : " flex justify-items-center" }>


                        <button onClick={onGoogleSign} 
                            className="w-full border border-gray-300 rounded flex items-center text-gray-800 p-3 justify-center hover:border-gray-500">
                            <img className="h-5 w-5" src="/images/google_g_logo.svg" /> <span className="ml-2">Sign up with Google</span>
                        </button>

                    </div>

                    {/* Google sign in loader  */}
                    <div className={isGoogleLoginLoading ? "mx-auto text-center" : "hidden" }>

                        {loadingDark('Loading')}

                    </div>

                    <div className="flex my-5">
                        <div className="border-b m-2 block w-full"></div>
                        <div className="text-gray-500">or</div>
                        <div className="border-b m-2 block w-full"></div>
                    </div>

                    <h2 className="text-2xl text-gray-800 font-semibold mb-6 text-center">Register with email</h2>

                    <form onSubmit={handleSubmit}>

                        {showValidationErrors()}

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                                Full name
                            </label>
                            <input 
                                className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" 
                                type="text" 
                                name="fullName"
                                placeholder="Full name"
                                required
                                onChange={handleChange} />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input 
                                className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" 
                                type="text" 
                                name="email"
                                placeholder="Email"
                                required
                                onChange={handleChange} />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <input 
                                className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" 
                                type={showPassword ? "text": "password"}
                                name="password"
                                placeholder="Password"
                                required
                                onChange={handleChange} />
                             <div className="flex flex-row justify-between">
                                <p className="text-gray-500 text-sm">Minimum length is 8 characters</p>
                                <a className="text-sm text-gray-600 cursor-pointer"
                                            onClick={toggleShowHidePassword}>
                                            { showPassword ?  
                                                <div className="flex flex-row"><img src="images/eye-off.svg" width="18" /> <div className="ml-1">hide</div></div> 
                                                : <div className="flex flex-row"><img src="images/eye.svg" width="18" /> <div className="ml-1">show </div></div>}
                                </a>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="text-gray-500">
                            <input 
                                name="optIn"
                                className="mr-2 leading-tight" 
                                onChange={handleChange}
                                type="checkbox"/><span className="text-sm">Keep me posted of new features and news!</span>
                            </label>
                        </div>

                        <div className="mb-4">
                            <button className="inline-block w-full py-4 px-8 leading-none text-white bg-gray-800 hover:bg-gray-900 rounded" 
                                disabled={isLoading}> 
                                { isLoading ? loadingLight('Sign up') : 'Sign up'} 
                            </button>
                        </div>

                    </form>
                    <div>
                        <a href={"/login?invitation_token="+queryParams.invitation_token} className="text-sm text-gray-700">Already have an account?</a>
                    </div>


                </div>


            </div>

           


        </div>
    )

    
}

export default InvitationRegister