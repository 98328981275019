export function emptyAreaChartOptions(){
    const options = {
        chart: {
          type: 'area',
          fontFamily: 'inherit',
          toolbar: {
            show: true,
          },
          animations: {
            enabled: true
          },
        },
        fill:{
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        stroke: {
            width: 2,
            lineCap: "round",
            curve: "smooth", //straight
        },
        series: [],
        xaxis: {
            type: 'datetime',
            tickAmount: 6            
        },
        yaxis:{
            min: 0,

        },
        grid: {
            strokeDashArray: 4,
            position: 'back'
        },
        dataLabels:{
          enabled: false
        },
        noData: {
            text: 'Loading...'
        },
        colors: ["#35bc29", "#2f66d0", "#d1c72e", "#9444bb"],
        
      }
    return options;
}


export function emptyStackedBarOptions() {

    const options = {
        chart: {
          type: 'bar',
          fontFamily: 'inherit',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true
          },
          stacked: true
        },
        fill:{
          opacity: 0.75,
          type: 'solid'
        },
        // stroke: {
        //     width: 2,
        //     lineCap: "round",
        //     curve: "smooth", //straight
        // },
        series: [],
        xaxis: {
            categories: [],
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                  year: 'yyyy',
                  month: 'MMM \'yy',
                  day: 'dd MMM',
                  hour: 'HH:mm'
                }
              }

        },
        yaxis:{
            min: 0,
            // max: 10,
            // forceNiceScale: true,
        },
        grid: {
            strokeDashArray: 4,
            position: 'back'
        },
        dataLabels:{
          enabled: false
        },
        // colors: ["#35bc29"],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'right',
            offsetY: 12,
            markers: {
                width: 10,
                height: 10,
                radius: 100,
            },
            itemMargin: {
                horizontal: 8,
                vertical: 8
            },
        },
        noData: {
            text: 'Loading...'
        },
        
    }

    return options;
}
