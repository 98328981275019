import React, { useState } from 'react'
import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

// taken from: https://headlessui.dev/react/listbox

function DropdownListbox({dataItems, onSelectedItem}) {

  const [selectedItem, setSelectedItem] = useState(dataItems[0]);

  const onChangeSelected = (item) => {
    console.log(item);

    setSelectedItem(item);

    onSelectedItem(item);
  }

  return (
    <Listbox value={selectedItem} onChange={(e) => onChangeSelected(e) }>
      
      <div className="relative mt-1">

        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
              <span className="block truncate">{selectedItem.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
        </Listbox.Button>

        <Listbox.Options className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {dataItems.map((dataItem, dataItemIdx) => (
              <Listbox.Option
                key={dataItemIdx}
                className={({ active }) =>
                  `${active ? 'text-indigo-900 bg-amber-100' : 'text-gray-900'}
                        cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={dataItem}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'font-medium' : 'font-normal'
                      } block truncate`}
                    >
                      {dataItem.name}
                    </span>
                    {selected ? (
                      <span
                        className={`${
                          active ? 'text-indigo-600' : 'text-indigo-600'
                        }
                              absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
        </Listbox.Options>

      </div>
    </Listbox>
  )
}

export default DropdownListbox;