import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;



export async function getMembers(){
    const url = baseUrl + `members`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}