import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function getBookingDays(year, weekNumber, floorId){

    const url = baseUrl + `bookingdays?week=${weekNumber}&year=${year}&floorId=${floorId}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function getBookingDay(date, floorId){
    const url = baseUrl + `bookingdays?date=${date}&floorId=${floorId}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}
