import _ from "lodash";
import moment from "moment";
import jwtDecode from 'jwt-decode';

export function isAuthed(){

    var userJson = localStorage.getItem("user");

    if (userJson === null || userJson === undefined) {
      return false;
    }
    
    var user = JSON.parse(userJson);

    const decoded = jwtDecode(user.token);


    const expTime = moment.unix(decoded.exp).utc();

    const nowDateTime = moment().utc();


    // verify user is in local storage, and the jwt exp is not expired.
    if (!_.isNil(user) && !_.isNil(user.token) && expTime > nowDateTime){
      return true;
    }

    return false;


}

export function getLocalUser() {
  var userJson = localStorage.getItem("user");
  var user = JSON.parse(userJson);
  return user;
}

export function isAdmin(){

  var userJson = localStorage.getItem("user");

  var user = JSON.parse(userJson);

  const decoded = jwtDecode(user.token);

  if (decoded.role === 'admin'){
    return true;
  }

  return false;

}




export function redirectIfFails(window, url){

  // sometimes history.push doesn't work, so use traditional way of redirecting
  //. but only redirect when it doesn't work
  
  if (window.pathname != url){
    window.location = url;
  }

}
