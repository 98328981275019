import { getLocalUser } from '../utils/Utils';
import { v4 as uuidv4 } from 'uuid';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function getFloors(){
    const url = baseUrl + `floors`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function getFloor({floorId}){
    const url = `${baseUrl}floors/${floorId}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function postFloors({name, capacity}){

    const url = baseUrl + `floors`;

    const user = getLocalUser();

    return axios({
        method: 'post',
        url: url,
        data: {
            id: uuidv4(),
            name: name,
            capacity: capacity,
            organisationId: user.organisationId
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function putFloors({floorId, name, capacity}){

    const url = baseUrl + `floors`;

    const user = getLocalUser();

    return axios({
        method: 'put',
        url: url,
        data: {
            id: floorId,
            name: name,
            capacity: capacity,
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function deleteFloor({floorId}){

    const url =  `${baseUrl}floors/${floorId}`;

    const user = getLocalUser();

    return axios({
        method: 'delete',
        url: url,
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}
