import React, { useState } from "react";
import LoaderLabel from "../components/LoaderLabel";

function OrganisationForm( {name, onNameChange, onSave} ){

    const [isLoading, setIsLoading] = useState(false);
    
    const handleSubmit = (event) => {

        event.preventDefault();

        setIsLoading(true);
        onSave();
        setIsLoading(false);
    }
    
    let inputStyle = "appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none";


    return(
        <div className="">
            <form onSubmit={handleSubmit}>
                <dl>
                    <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm leading-5 font-medium text-gray-500">
                        Name
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        <input 
                            className={inputStyle}
                            type="text" 
                            value={name}
                            name="name"
                            placeholder="First Floor"
                            onChange={(e)=> onNameChange(e.target.value)}
                            />
                    </dd>
                    </div>


                    <div className="flex justify-end py-5">
                            <button 
                            type="submit"
                            className="p-2 leading-none text-white bg-indigo-700 hover:bg-indigo-800 rounded shadow" 
                            disabled={isLoading}>
                                <LoaderLabel darkMode={false} isLoading={isLoading} label="Save" loadingLabel="Saving" />
                            </button>
                        </div>

                </dl>
                
            </form>
        </div>
    )
}

export default OrganisationForm;