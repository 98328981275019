import React, {useState} from "react";
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom";

import { getLocalUser } from "../utils/Utils";


function Navbar(){

    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const history = useHistory();
    const localUser = getLocalUser();

    function onClick(){
        setIsOpen(prevState => !prevState)
    }

    function onDropdownClick(){
        setIsDropdownOpen(prevState => !prevState);
    }

    function onSignOut(){
        localStorage.removeItem("user");

        // redirect
        history.push('/login');
        window.location = "/login";
    }

    return (

        <nav className="sm:flex sm:items-center sm:justify-between bg-indigo-700 sm:px-4 sm:py-3">

            <div className="flex items-center text-white justify-between px-4 py-3 sm:p-0">

                <div className="flex items-center">
                    <div className="text-base font-semibold tracking-tight">
                        <a href="/calendar">
                            <span className="flex items-center">
                            <img src="/images/officeMixerLogowhite.svg" className="h-10 w-10" />
                            <span className="font-extrabold pl-1">OfficeMixer</span>
                            </span>
                        </a>
                    </div>
                </div>

            
                <div className="sm:hidden">
                    <button 
                        onClick={onClick} 
                        className="block px-3 py-2 border rounded text-white border-teal-400 hover:text-white hover:border-white">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div className={ (isOpen ? " block " : "hidden ")  + " px-2 pt-2 pb-4 sm:flex sm:p-0 nav-block text-sm" }>

                {/* Nav for desktop */}
                <div className="relative hidden sm:block">
                    <a className="relative z-10 block text-sm text-white px-2 py-1 cursor-pointer" onClick={onDropdownClick}>
                       
                        <div className="flex">
                            
                            <div className="mr-1">
                                {/* user icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="hover:text-gray-200 feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </div>

                            <div className="username">
                                {localUser.fullName}
                            </div>
                        
                            {/* chevron down */}
                            <svg className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </a>
                    {
                        isDropdownOpen ?
                        <button className="fixed inset-x-0 inset-y-0 h-full w-full cursor-default"
                                tabIndex="-1"
                                onClick={onDropdownClick}></button>
                        : null
                    }
                    { isDropdownOpen ?
                    <div className="dropdown-menu absolute mt-2 right-0 bg-white rounded shadow-lg w-32">
                        <a className="block px-3 py-2 text-gray-800 text-sm hover:bg-indigo-400 hover:text-white cursor-pointer flex items-center" href="/calendar">
                            {/* calendar icon */}
                            <svg className="h-4 w-4 mr-2"width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 18V7H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z" fill="currentColor" /></svg>
                            Calendar
                        </a>
                        <a className="block px-3 py-2 text-gray-800 text-sm hover:bg-indigo-400 hover:text-white cursor-pointer flex items-center" href="/analytics">
                           {/* insights icon */}
                            <svg className="h-4 w-4 mr-2"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15 8H19V20H5V10H9V4H15V8ZM13 6H11V18H13V6ZM15 10V18H17V10H15ZM9 12V18H7V12H9Z" fill="currentColor" /></svg>
                            Analytics
                        </a>
                        <a className="block px-3 py-2 text-gray-800 text-sm hover:bg-indigo-400 hover:text-white cursor-pointer flex items-center" href="/settings">
                            {/* layout icon */}
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 7H7V9H9V7Z" fill="currentColor" /><path d="M7 13V11H9V13H7Z" fill="currentColor" /><path d="M7 15V17H9V15H7Z" fill="currentColor" /><path d="M11 15V17H17V15H11Z" fill="currentColor" /><path d="M17 13V11H11V13H17Z" fill="currentColor" /><path d="M17 7V9H11V7H17Z" fill="currentColor" /></svg> 
                            Settings
                        </a>
                        <a className="block px-3 py-2 text-gray-800 text-sm hover:bg-indigo-400 hover:text-white cursor-pointer flex items-center" href="/organisation" >
                            {/* organisation icon */}
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 15H19V17H17V15Z" fill="currentColor" /><path d="M19 11H17V13H19V11Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 7H23V21H1V3H13V7ZM8 5H11V7H8V5ZM11 19V17H8V19H11ZM11 15V13H8V15H11ZM11 11V9H8V11H11ZM21 19V9H13V11H15V13H13V15H15V17H13V19H21ZM3 19V17H6V19H3ZM3 15H6V13H3V15ZM6 11V9H3V11H6ZM3 7H6V5H3V7Z" fill="currentColor" /></svg> 
                            Organisation
                        </a>
                        <div className="border-t w-full"></div>
                        <a className="block px-3 py-2 text-gray-800 text-sm hover:bg-indigo-400 hover:text-white cursor-pointer flex items-center" onClick={onSignOut}>
                            {/* sign out icon */}
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z" fill="currentColor" /><path d="M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z" fill="currentColor" /></svg>
                            Sign out
                        </a>
                    </div>
                    : null}
                </div>

                {/* Nav for mobile */}
                <div className="relative sm:hidden">
                    {/* <a className="relative z-10 block text-sm text-white px-2 py-1 cursor-pointer" onClick={onDropdownClick}>
                        <img src="images/settings.svg" className="h-4 w-4 mr-2" /> Dashboard
                    </a> */}
                    
                    <div className="dropdown-mobile-menu">
                        <a className="block px-2 py-1 text-gray-100 hover:text-gray-300 cursor-pointer flex items-center" href="/calendar">
                            <svg className="h-4 w-4 mr-2"width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H8Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 18V7H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z" fill="currentColor" /></svg>
                            Calendar
                        </a>
                        <a className="block px-2 py-1 text-gray-100 hover:text-gray-300 cursor-pointer flex items-center" href="/analytics">
                            <svg className="h-4 w-4 mr-2"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15 8H19V20H5V10H9V4H15V8ZM13 6H11V18H13V6ZM15 10V18H17V10H15ZM9 12V18H7V12H9Z" fill="currentColor" /></svg>
                            Analytics
                        </a>
                        <a className="block px-2 py-1 text-gray-100 hover:text-gray-300 cursor-pointer flex items-center" href="/settings">
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 7H7V9H9V7Z" fill="currentColor" /><path d="M7 13V11H9V13H7Z" fill="currentColor" /><path d="M7 15V17H9V15H7Z" fill="currentColor" /><path d="M11 15V17H17V15H11Z" fill="currentColor" /><path d="M17 13V11H11V13H17Z" fill="currentColor" /><path d="M17 7V9H11V7H17Z" fill="currentColor" /></svg> 
                            Settings
                        </a>
                        <a className="block px-2 py-1 text-gray-100 hover:text-gray-300 cursor-pointer flex items-center" href="/organisation">
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 15H19V17H17V15Z" fill="currentColor" /><path d="M19 11H17V13H19V11Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M13 7H23V21H1V3H13V7ZM8 5H11V7H8V5ZM11 19V17H8V19H11ZM11 15V13H8V15H11ZM11 11V9H8V11H11ZM21 19V9H13V11H15V13H13V15H15V17H13V19H21ZM3 19V17H6V19H3ZM3 15H6V13H3V15ZM6 11V9H3V11H6ZM3 7H6V5H3V7Z" fill="currentColor" /></svg> 
                            Organisation
                        </a>
                        <a className="block px-2 py-1 text-gray-100 hover:text-gray-300 cursor-pointer flex items-center" onClick={onSignOut}>
                            <svg className="h-4 w-4 mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z" fill="currentColor" /><path d="M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z" fill="currentColor" /></svg>
                            Sign out
                        </a>
                    </div>
                    
                </div>
                    
                
            </div>

            

        </nav>

       

    )

}

export default Navbar