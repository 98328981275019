import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import FloorForm from "../components/FloorForm";
import { postFloors } from "../Api/Floors";


function FloorAdd(){

    const history = useHistory();

    const [name, setName] = useState("")

    const [capacity, setCapacity] = useState(0);

    const addFloor = async () => {
        console.log(name);
        console.log(capacity);


        await postFloors({
            capacity: parseInt(capacity),
            name: name
        });

        // redirect to page
        history.push('/organisation');
    }

    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">

                    
                    <div className="settings-content-container px-6 w-full">
                    <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Add Floor</h3>
                        <FloorForm onNameChange={setName} onCapacityChange={setCapacity} onSave={addFloor} />
                    </div>
                </div>

               

            </div>

        </div>
    )

    
}

export default FloorAdd;