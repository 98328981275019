import React, { useEffect, useState } from "react";
import ApexCharts from 'apexcharts';
import Navbar from "./Navbar";
import { Helmet } from 'react-helmet';
import { getAnalytics } from "../Api/Analytics";
import { getMonthlyAnalytics } from "../utils/Api";
import { toast } from "react-toastify";
import { DateTime } from 'luxon';
import { Backdrop } from "@material-ui/core";
import {  emptyAreaChartOptions, emptyStackedBarOptions } from "../utils/ChartHelper";
import LoaderLabel from "../components/LoaderLabel";
import Chart from 'react-apexcharts';
import DropdownListbox from "./DropdownListbox";
import { getFloors } from "../Api/Floors";
import _ from "lodash";

function AnalyticsNew(){

    const now = DateTime.now();
    const theYear = now.year;
    const month = now.month;
    const day = now.day;

    const [isWeekActive, setIsWeekActive] = useState(false);
    const [isMonthActive, setIsMonthActive] = useState(true);
    const [isYearActive, setIsYearActive] = useState(false);


    const [areaChartOptions, setAreaChartOptions] = useState(emptyAreaChartOptions());
    const [areaChartSeries, setAreaChartSeries] = useState([]);

    const [stackBarOptions, setStackBarOptions] = useState(emptyStackedBarOptions());
    const [stackBarSeries, setStackBarSeries] = useState([]);

    const [analyticsData, setAnalyticsData] = useState({});

    const [floors, setFloors] = useState([]);


    const fetchChartData = async () =>{

        try {
            
            // get floors 
            const response = await getFloors();
  
            const mappedFloors = response.data.map((f) =>{
                return {
                ...f,
                name: `${f.name} (${f.capacity})`,
                selected: false,
                }
            });
  
            setFloors(mappedFloors);

            // get chart data
            const oneYearResponse = await getAnalytics(theYear, month, day, 365);

            const thirtyDayResponse = await getAnalytics(theYear, month, day, 30);

            setAnalyticsData(thirtyDayResponse.data);

            loadAreaChart(oneYearResponse.data);

            const firstFloor = _.first(mappedFloors);
            loadStackedChart(thirtyDayResponse.data, firstFloor);

            
        }
        catch(error){
            toast.error(error.message);
        }

    }

    

    const convertToSeriesData = (dataPoints) => {
        // [{
        //     name: 'Team A,
        //     data: [44, 55, 41, 67, 22, 43]
        //   }, {
        //     name: 'Team C',
        //     data: [11, 17, 15, 15, 21, 14]
        //   }, {
        //     name: 'Team D',
        //     data: [21, 7, 25, 13, 22, 8]
        //   }]
        // The aim is to convert the data to look like the above format

        const series = dataPoints.map((x)=> {
            return {
                name: x.teamName,
                data: x.data
            }
        });

        return series;
    }


    const convertToXAxisLabels = (dataPoints) =>{

        const labels = dataPoints.map((x)=>{
            return x.dayLabel
        });

        return labels;

    }


    useEffect(() => {

        fetchChartData();

    }, [])

    
    const loadAreaChart = (responseData) =>{

        const series = responseData.apexAreaChartSeries.map((x)=>{

            return {
                name: x.floorName,
                data: x.data.map((d)=>{  return [d.unixTime, d.data] })
            }
         });       

        const labels = convertToXAxisLabels(responseData.dates);

        const newOptions = { 
            ...areaChartOptions,  
            xaxis: {
                ...areaChartOptions.xaxis, 
                categories: labels, // update categories xaxis label
                min: now.minus({months:1}).toMillis(),
                max: now.toMillis(),
            }  
        };

        setAreaChartOptions(newOptions);

        setAreaChartSeries(series);

    }

    const loadStackedChart = (responseData, selectedFloor) =>{


        const floorData = _.find(responseData.apexStackedSeries, (f) => {
            return f.floorId === selectedFloor.id;
        });

        const series = convertToSeriesData(floorData.data);

        const labels = convertToXAxisLabels(responseData.dates);

        const newOptions = { 
            ...stackBarOptions,  
            xaxis: {
                ...stackBarOptions.xaxis, 
                categories: labels // update categories xaxis label
            }  
        };

        setStackBarOptions(newOptions);
        
        setStackBarSeries(series);

    }

    const onChartPeriodChange = (event, period) =>{
        event.preventDefault();

        // https://github.com/apexcharts/react-apexcharts/issues/45

        let lastPeriod;

        if (period === 7){
            lastPeriod = now.minus({weeks:1});
            setIsWeekActive(true);
            setIsMonthActive(false);
            setIsYearActive(false);
        }
        else if (period === 30){

            lastPeriod = now.minus({months:1});

            setIsWeekActive(false);
            setIsMonthActive(true);
            setIsYearActive(false);

        }
        else if (period ===365){
            lastPeriod = now.minus({months:12});

            setIsWeekActive(false);
            setIsMonthActive(false);
            setIsYearActive(true);

        }
        else{
            lastPeriod = now.minus({months:1});

            setIsWeekActive(false);
            setIsMonthActive(true);
            setIsYearActive(false);
        }


        const newOptions = { 
            ...areaChartOptions,  
            xaxis: {
                ...areaChartOptions.xaxis, 
                min: lastPeriod.toMillis(),
                max: now.toMillis(),
            }  
        };

        setAreaChartOptions(newOptions);

    }

    const onFloorChanged = (floorSelected) =>{

        console.log(floorSelected);

        loadStackedChart(analyticsData, floorSelected)
    }


    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

            <Helmet>
                <title>OfficeMixer - Analytics</title>
            </Helmet>  



                <div className="w-11/12 mx-auto">

                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    
                        <div className="border rounded p-5">
                            
                            <h3 className="text-2xl pb-5 leading-10 font-medium text-gray-900">
                                Occupancies
                            </h3>
                            <div className="flex">
                                <button className={ ' px-3 py-1 focus:outline-none rounded hover:bg-green-500 hover:text-white mx-1 border' + (isWeekActive ? ' text-white bg-green-500 ' : '' )}  onClick={(e) => onChartPeriodChange(e, 7)}>1 week</button>
                                <button className={ ' px-3 py-1 focus:outline-none rounded hover:bg-green-500 hover:text-white mx-1 border' + (isMonthActive ? ' text-white bg-green-500 ' : '' )} onClick={(e) => onChartPeriodChange(e, 30)}>1 month</button>
                                <button className={ ' px-3 py-1 focus:outline-none rounded hover:bg-green-500 hover:text-white mx-1 border' + (isYearActive ? ' text-white bg-green-500 ' : '' )}  onClick={(e) => onChartPeriodChange(e, 365)}>1 year</button>
                            </div>

                            <Chart options={areaChartOptions} series={areaChartSeries} type="area" />
                        </div>

                        <div className="border rounded p-5">

                            <h3 className="text-2xl pb-5 leading-10 font-medium text-gray-900">
                                Occupancies by team - last 30 days
                            </h3>

                            <div className="w-1/4"> 
                                { floors.length > 0 ? <DropdownListbox dataItems={floors} onSelectedItem={onFloorChanged} /> : null }
                            </div>  
                            
                            <Chart options={stackBarOptions} series={stackBarSeries} type="bar" />
                        </div>

                       
                    </div>

                   

                    

                </div>


            </div>

        </div>
    )

}

export default AnalyticsNew;