import React, {useState} from "react";
import { last } from "lodash";

function SideNav(){

    const [isOpen, setIsOpen] = useState(false);

    const onBurgerMenuClick = () =>{
        setIsOpen(prevState => !prevState)
    }
    

    const isActive = (linkName) => {

        const urlSegments = window.location.pathname.split('/');

    
        return last(urlSegments).toLocaleLowerCase().includes(linkName.toLocaleLowerCase());
    }

    const cssActive = (linkName) =>{

        if (isActive(linkName)){
            // return a border-left to indicate you are on the specific page.
            return 'border-l-2 font-bold';
        }

        // return some spacing
        return ' ml-1 '
    }


    return(
        <div>

            {/* Nav for desktop */}
            <div className="side-desktop-nav relative hidden sm:block">
                <ul>
                    <li className={ cssActive('settings') + " px-3 my-2 hover:text-blue-700  border-blue-500 hover:border-l-2"}><a className="block" href="/settings">Profile</a></li>
                    <li className={ cssActive('members') + " px-3 my-2 hover:text-blue-700  border-blue-500 hover:border-l-2"}><a className="block" href="/settings/members">Members</a></li>
                    
                </ul>
            </div>

            {/* Nav for mobile */}
            <div className="side-mobile-nav relative sm:hidden ml-1">

                {/* burger menu trigger */}
                <div className="sm:hidden mobile-burger-menu">
                    <button 
                        onClick={onBurgerMenuClick}
                        className="block px-3 py-2">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>

                {/* Mobile nav options */}
                <div className={ (isOpen ? " block " : "hidden ")  + " " }>
                    <ul>
                        <li><a className="" href="/settings">Profile</a></li>
                        <li><a className="" href="/settings/members">Members</a></li>
                    </ul>
                </div>
            </div>

        </div>
    )

}

export default SideNav