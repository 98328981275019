import React, { useState } from "react";

function TeamFilter({ teams, onTeamFilterToggle }){

    const [filterHidden, setfilterHidden] = useState(true);

    const onFilterToggle = (event, item) => {

        event.preventDefault();

        onTeamFilterToggle(item);

    }

    const onFilterClickHandler = (event) =>{
        event.preventDefault();


        setfilterHidden(value => !value);

    }

    const renderFilter = (item, index)=> {
        return (
          <div key={index} className="mr-2 my-1">
    
            {item.enabled ? renderFilterEnabled(item) : renderFilterDisabled(item) }
            
          </div>
        )
    }
    
    const renderFilterEnabled = (item) => {
        return (
            <button 
                onClick={(e)=> {onFilterToggle(e, item)}}
                className="focus:outline-none outline-none border-blue-500 border-2 px-2 py-1 rounded-lg text-blue-500 font-semibold  hover:bg-blue-500 hover:bg-border-none hover:text-white flex">
        
            {/* tick icon */}
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z" fill="currentColor"/>
            </svg>
        
            {item.name} ({item.users.length})
        
            </button>
        )
    }
    
    const renderFilterDisabled = (item) => {
        return (
            <button
                onClick={(e)=> {onFilterToggle(e, item)}} 
                className="focus:outline-none outline-none border-2 px-2 py-1 rounded-lg font-semibold text-gray-600 hover:bg-blue-500 hover:text-white flex">
        
                {item.name} ({item.users.length})
        
            </button>
        )
    }


    return (

    <div className="filter-panel text-sm">

        <div className="flex">
            <button className="font-semibold flex focus:outline-none"
                onClick={onFilterClickHandler}>
                Filters { 
                        filterHidden ?

                        <img className="h-5 w-5 pl-1" src="/images/cheveron-down.svg" />
                        :
                        <img className="h-5 w-5 pl-1" src="/images/cheveron-up.svg" />
                }
            </button>
        </div>
        <div className="flex flex-wrap">

            { filterHidden ? null : teams.map(renderFilter) }

        </div>

        
    </div>


    
    )



}

export default TeamFilter;