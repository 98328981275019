

import React, {useState, useEffect} from "react"
import { useHistory } from 'react-router-dom'
import { sendForgotPasswordEmail } from '../Api/User.js' 
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

toast.configure();

// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'white',
    },
  }));

function ForgotPassword(){

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(()=>{},[isLoading])

    async function handleSubmit(event){

        event.preventDefault();

        setIsLoading(true);

        try{ 
            const response = await sendForgotPasswordEmail(email);
            if (response.status === 200){

                history.push('/forgot-password-sent');
                window.location = '/forgot-password-sent';
                
            }
        }
        catch(error){
            if(error.response.status === 400 && error.response.data.errors){
                // validation errors.
                const {Email} = error.response.data.errors;

                const errors = [...(Email? Email : [])];
                

                setValidationErrors(errors);
           }
           else{
            toast.error(error.message);            
           }
            
            setIsLoading(false);
        }
    }


    let inputStyle = "appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none";

    function loading(buttonLabel){
        
        if (isLoading){
            return(<span className=""><CircularProgress size={15} className={classes.buttonProgress} /></span>);
        }
        return buttonLabel;
    }

    function showValidationErrors(){
        return(
            validationErrors.length > 0 ?
        <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm my-5" role="alert">
            <span className="block sm:inline">
                <ul className="list-disc px-5">
                {
                    validationErrors.map((value, index)=>{
                        return <li key={index}> {value} </li>
                    })
                }
                </ul>

            </span>
        </div> 
        : null
        )
    }

    return(
        <div className="mt-16">

            <Helmet>
                <title>OfficeMixer - Forgot password</title>
            </Helmet>

        
            <div className="w-full max-w-md mx-auto flex flex-col items-center"> 

                <img src="/images/officeMixerLogoblueblack.svg" className=" h-16 w-16" />
                <h1 className="w-full mx-auto text-center font-semibold my-6 tracking-tight">
                    OfficeMixer
                </h1>
                <div className="lg:border w-full p-5 rounded mt-8">

                    <h2 className="text-2xl text-gray-800 font-semibold mb-6">Forgot Password?</h2>

                    <p className="my-5">Enter the email address that you used to sign up.</p>

                    <div className="forget-">
                    <form onSubmit={handleSubmit}>

                    {showValidationErrors()}
                    
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input 
                            className={inputStyle}
                            type="text" 
                            name="email"
                            placeholder="Email"
                            onChange={(e)=> setEmail(e.target.value)}
                            required
                            />
                    </div>

                    
        
                    <div className="mb-4">
                        <button 
                            type="submit"
                            className="inline-block w-full py-4 px-8 leading-none text-white bg-gray-800 hover:bg-gray-900 rounded shadow" 
                            disabled={isLoading}>
                                { loading('Reset Password') }
                        </button>
                    </div>

                </form>

                    </div>

                    <div className="flex justify-between">
                        <div>
                            <a href="/register" className="text-sm text-gray-700">Create an account</a>
                        </div>
                        <div>
                            <a href="/login" className="text-sm text-gray-700">I remember my password</a>
                        </div>
                    </div>

                </div>
            </div>

           


        </div>
    )

    
}

export default ForgotPassword