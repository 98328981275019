
import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { getSignInWithGoogleUri, signInWithEmail } from "../utils/Api.js";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonDarkProgress: {
        color: 'black',
      },
      buttonLightProgress:{
          color: 'white'
      }

  }));

toast.configure();

function LogIn(){

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    const history = useHistory();

    function handleChange(event){
        const {name, value, type, checked} = event.target;

        switch(name){
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
        }
    }

    const toggleShowHidePassword = (event) => {

        event.preventDefault();

        setShowPassword(!showPassword);
    }

    async function handleSubmit(event){

        event.preventDefault();

        setIsLoading(true);


        try{
            
            const response = await signInWithEmail({email: email, password: password});

            if (response.status === 200){

                // set local storage of return auth user
                // then we can use the stored token in future api responses.
                localStorage.setItem("user", JSON.stringify(response.data));
        
                if (response.data.floors === null  || response.data.floors?.length === 0){
                    // when organisationId is null, google domain user is first time user
                    // redirect
                    history.push('/org-setup');
                    window.location = '/org-setup';
                }
                else if (response.data.teamId === null){
                    // when teamId is null, Google domain user has an existing org, but not team assigned.
                    // redirect
                    history.push('/user-setup');
                    window.location = '/user-setup';
                }
                else {
                    // redirect
                    history.push('/calendar');
                    window.location = '/calendar';
                }
            }
                        
        }
        catch (error){
            if (error.response?.status == 401){
                
                setValidationErrors([error.response.data.message]);

            }
            else {
                toast.error(error.message);
            }
        }


        setIsLoading(false);
    }
    
    function onGoogleSign(){
        

        setIsGoogleLoginLoading(true);

        getSignInWithGoogleUri()
        .then((response)=>{

            history.push(response.data.url);
            window.location = response.data.url;
        })
        .catch((error)=>{

            
            toast.error(error.message);

            setIsGoogleLoginLoading(false);
        });

    }



    function loadingDark(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonDarkProgress} /> {buttonLabel}</span>);
       
    }

    function loadingLight(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonLightProgress} /> {buttonLabel}</span>);
       
    }

    let inputStyle = "appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none";

    function showValidationErrors(){
        return(
            validationErrors.length > 0 ?
        <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm my-5" role="alert">
            <span className="block sm:inline">
                <ul className="list-disc px-5">
                {
                    validationErrors.map((value, index)=>{
                        return <li key={index}> {value} </li>
                    })
                }
                </ul>

            </span>
        </div> 
        : null
        )
    }
    

    return(
        <div className="mt-16">

            <Helmet>
                <title>OfficeMixer - Login</title>
            </Helmet>

            <div className="w-full max-w-md mx-auto flex flex-col items-center"> 

                <img src="/images/officeMixerLogoblueblack.svg" className=" h-16 w-16" />
                <h1 className="w-full mx-auto text-center font-semibold my-6 tracking-tight">
                    OfficeMixer
                </h1>

                <div className="lg:border w-full p-5 rounded mt-8">

                    <h2 className="text-2xl text-gray-800 font-semibold mb-6 text-center">Log in</h2>

                    <div className={isGoogleLoginLoading ? " hidden mx-auto" : " flex justify-items-center" }>


                        <button onClick={onGoogleSign} 
                            className="w-full border border-gray-300 rounded flex items-center text-gray-800 p-3 justify-center hover:border-gray-500">
                            <img className="h-5 w-5" src="/images/google_g_logo.svg" /> <span className="ml-2">Sign in with Google</span>
                        </button>

                    </div>

                    {/* Google sign in loader  */}
                    <div className={isGoogleLoginLoading ? "mx-auto text-center" : "hidden" }>

                        {loadingDark('Loading')}

                    </div>


                    <div className="flex my-5">
                        <div className="border-b m-2 block w-full"></div>
                        <div className="text-gray-500">or</div>
                        <div className="border-b m-2 block w-full"></div>
                    </div>

                    <div className="login-area">
                        <form onSubmit={handleSubmit}>
                            
                            {showValidationErrors()}

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input 
                                    className={inputStyle}
                                    type="text" 
                                    name="email"
                                    placeholder="Email"
                                    onChange={handleChange}
                                    required
                                    />
                            </div>

                            <div className="mb-6">
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                        Password
                                    </label>
                                    
                                </div>
                                <input 
                                    className={inputStyle} 
                                    type={showPassword ? "text": "password"}
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleChange} 
                                    required />
                                <div className="flex justify-end mt-2">
                                    <a className="text-sm text-gray-600 cursor-pointer"
                                        onClick={toggleShowHidePassword}>
                                        { showPassword ?  
                                            <div className="flex flex-row"><img src="images/eye-off.svg" width="18" /> <div className="ml-1">hide</div></div> 
                                            : <div className="flex flex-row"><img src="images/eye.svg" width="18" /> <div className="ml-1">show </div></div>}
                                    </a>
                                </div>
                            </div>

                            <div className="mb-4">
                                <button 
                                    type="submit"
                                    className="inline-block w-full py-4 px-8 leading-none text-white bg-gray-800 hover:bg-gray-900 rounded shadow" 
                                    disabled={isLoading}>


                                    { isLoading ? loadingLight('Login') : 'Login'}

                                </button>
                            </div>

                        </form>


                    </div>

                    <div className="flex justify-between">
                        <div>
                            <a href="/register" className="text-sm text-gray-700">Create an account</a>
                        </div>
                        <div>
                            <a href="/forgot-password" className="text-sm text-gray-700">Forgot password?</a>
                        </div>
                    </div>

                    
                </div>


            </div>

           


        </div>
    )

    
}

export default LogIn