import { getLocalUser } from '../utils/Utils';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;


export async function sendForgotPasswordEmail(email){

    const url = baseUrl + `user/forgot-password`;

    return axios({
        method: 'post',
        url: url,
        data: {
            email: email
        }
    });
}

export async function resetPassword({userId, code, newPassword}){

    const url = baseUrl + `user/reset-password`;

    return axios({
        method: 'post',
        url: url,
        data: {
            userId: userId,
            code: code,
            newPassword: newPassword
        }
    });
}


export async function updateUserRole({role, userIdToUpdate}){
    const url = baseUrl + `user/role`;

    const user = getLocalUser();

    return axios({
        method: 'post',
        url: url,
        data: {
            role: role,
            userIdToUpdate: userIdToUpdate
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function getUser(){

    const url = baseUrl + `user`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function getUsers(){

    const url = baseUrl + `users`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

export async function putUser({data}){
    const url = baseUrl + `user`;

    const user = getLocalUser();

    return axios({
        method: 'put',
        url: url,
        data: {
            id:  user.id,
            ...data
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });
}

