import { getLocalUser } from '../utils/Utils';
import { v4 as uuidv4 } from 'uuid';

const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function getTeams({floorId}){
    const url = baseUrl + `teams/` + floorId;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function getTeamById({teamId}){
    const url = baseUrl + `teams?teamId=` + teamId;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}
export async function postTeam({name, floorId}){

    const url = baseUrl + `teams`;

    const user = getLocalUser();

    return axios({
        method: 'post',
        url: url,
        data: {
            id: uuidv4(),
            name: name,
            floorId: floorId
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function putTeam({teamId, name, users}){

    const url =  `${baseUrl}teams/${teamId}`;

    const user = getLocalUser();

    return axios({
        method: 'put',
        url: url,
        data: {
            name: name,
            users: users,
        },
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

export async function deleteTeam({teamId}){

    const url =  `${baseUrl}teams/${teamId}`;

    const user = getLocalUser();

    return axios({
        method: 'delete',
        url: url,
        headers: {'Authorization': `Bearer ${user.token}`}
    });

}

