import React, { useEffect, useState } from "react";
import { getSettings, updateSettings, getGoogleCalConsentUri } from "../utils/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'


toast.configure();

function Settings(){

    const [settings, setSettings] = useState({});
    
    const history = useHistory();

    useEffect(()=>{

        getSettings().then((response)=>{

            setSettings(response.data);
            

        })
        .catch((error)=>{
            toast.error(error.message);
        });


    },[]);


    async function enableGoogleCal(){
        
        try{
            const response = await getGoogleCalConsentUri();

            history.push(response.data.url);
            window.location = response.data.url;
        }
        catch(error){
            toast.error(error.message);
        }

    }

    return(
        <div>
        
            <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Settings</h3>

            <div>
                <form>
                <dl>
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Full name
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {settings.fullName}
                        </dd>
                    </div>

                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Email address
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {settings.email}
                        </dd>
                    </div>

                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Organisation
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {settings.orgName}
                        </dd>
                    </div>

                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Google Calender
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            { 
                                settings.googleCalSyncEnabled ?  
                                
                                <div className="flex">
                                    <img src="images/check-circle.svg" width="18" /> <p className="pl-1">Google Calendar connected</p> 
                                </div>
                                
                                : 
                                <>
                                <button 
                                    type="button"
                                    className="border-gray-400 border-1 hover:border-gray-600 border px-2 py-2 rounded focus:online-none flex"
                                    onClick={enableGoogleCal}
                                >
                                    <img src="images/google-cal-icon.svg" width="18" /> <span className="pl-1">Connect Google Calendar</span>
                                    </button>
                                <p className="text-sm text-gray-500">Once connected, any days booked will also appear in your Google calendar</p>
                                </>
                            }
                            
                        </dd>
                    </div>


                
                </dl>
                </form>
            </div>

        </div>
    )
}

export default Settings