import React, { useEffect} from "react"
import { useHistory } from 'react-router-dom'
import { exchangeForGoogleCalToken } from "../utils/Api.js";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from "qs";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'black',
    },
  }));

toast.configure();

function GoogleCalSyncRedirect(){

    const classes = useStyles();
    const history = useHistory();

    useEffect(()=>{
        
        const queryString = window.location.search;

        const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });

        const doExchangeForGoogleToken = async (code) =>{
            try{

                const response = await exchangeForGoogleCalToken(code);

                if (response.status == 200) {
         
                    // redirect
                    history.push('/settings');
                    window.location = "/settings";
                }
            }
            catch(error){
                toast.error(error.message);
            }

        }
        
        
        if (queryParams.code){
            doExchangeForGoogleToken(queryParams.code);
        }

    },[]);

    function loading(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonProgress} /> {buttonLabel}</span>);
       
    }

    return(
        <div className="mt-16">

            <div className="w-full max-w-md lg:border p-5 mx-auto"> 


                <h1 className="text-2xl text-gray-800 font-semibold mb-6 text-center">Verifying</h1>


                <div className="mx-auto text-center">

                    {loading('Just a sec...')}

                </div>


            </div>

           


        </div>
    

    );
}

export default GoogleCalSyncRedirect;