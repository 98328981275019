
const axios = require('axios');

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function getLocalUser() {
    var userJson = localStorage.getItem("user");
    var user = JSON.parse(userJson);
    return user;
}

export async function getAnalytics(year, month, day, days){

    const url = baseUrl + `analytics?year=${year}&month=${month}&day=${day}&days=${days}`;

    const user = getLocalUser();

    return axios.get(url, {
        headers: {'Authorization': `Bearer ${user.token}`}
    });
     
}

