import React, {useState} from "react"
import { Helmet } from 'react-helmet';

function ForgotPasswordSent(){

    const [isLoading, setIsLoading] = useState(false);

    let spinner = isLoading ? <i className="ml-1 fa-spin fas fa-circle-notch"></i> : null ;

    return(
        <div className="mt-16">

            <Helmet>
                <title>OfficeMixer - Reset password</title>
            </Helmet>

            <div className="w-full max-w-md mx-auto flex flex-col items-center"> 

                <img src="/images/officeMixerLogoblueblack.svg" className=" h-16 w-16" />
                <h1 className="w-full mx-auto text-center font-semibold my-6 tracking-tight">
                    OfficeMixer
                </h1>

                <div className="lg:border w-full p-5 rounded mt-8">


                    <h1 className="text-2xl text-gray-800 font-semibold mb-6">Password Reset Sent.</h1>

                    <div className="mt-3 mb-5">
                        <p>Go ahead and check your email for a password reset. The email may take a couple of minutes to arrive.</p>
                    </div>

                
                    <div className="flex justify-between">
                        <div>
                            <a href="/register" className="text-sm text-gray-700">Create an account</a>
                        </div>
                        <div>
                            <a href="/login" className="text-sm text-gray-700">I remember my password</a>
                        </div>
                    </div>
                </div>
            </div>

           


        </div>
    )

    
}

export default ForgotPasswordSent