import React, { useState } from "react";
import LoaderLabel from "./LoaderLabel";

// had to create this component so that an independent spinner can be shown for loading, otherwise could have put it within UserList
function UserListButtonItem({user, onDeleteClick}) {

    const [isLoading, setIsLoading] = useState(false);

    const onButtonClickHandler = (event, item) =>{

        event.preventDefault();

        setIsLoading(true);

        onDeleteClick(item);

        setIsLoading(false);

        console.log('chain');
    }



    return(
        
        
        <button className="focus:outline-none" onClick={(event) => onButtonClickHandler(event, user)} title="Remove user from team">

            
            <LoaderLabel darkMode={true} isLoading={isLoading} />
            
            { isLoading == false ? 
                <svg className="text-gray-600" width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
                        fill="currentColor"
                    />
                    <path d="M9 9H11V17H9V9Z" fill="currentColor" />
                    <path d="M13 9H15V17H13V9Z" fill="currentColor" />
                </svg> : null }
            

        </button>

          
    )
}

export default UserListButtonItem;