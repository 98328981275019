import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonDarkProgress: {
        color: 'black',
      },
      buttonLightProgress:{
          color: 'white'
      }

  }));


function LoaderLabel({darkMode, isLoading, label, loadingLabel }){

    const classes = useStyles();

    function loadingDark(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonDarkProgress} /> {buttonLabel}</span>);
       
    }

    function loadingLight(buttonLabel){
       
        return(<span className=""><CircularProgress size={15} className={classes.buttonLightProgress} /> {buttonLabel}</span>);
       
    }

    if (darkMode === true){
        return(
            <React.Fragment>
                { isLoading ? loadingDark(loadingLabel) : label }
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            { isLoading ? loadingLight(loadingLabel) : label }
        </React.Fragment>
    )
    
}

export default LoaderLabel;