import React, {useState} from "react"
import { useHistory } from 'react-router-dom'
import { resetPassword }  from "../Api/User";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'white',
    },
  }));

toast.configure();

function ResetPassword(){

    const classes = useStyles();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const code = params.get('code');
    const [password, setPassword] = useState("");
    const [resetComplete, setResetComplete] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();

    const toggleShowHidePassword = (event) => {

        event.preventDefault();

        setShowPassword(!showPassword);
    }
    

    async function handleSubmit(event){

        event.preventDefault();


        setIsLoading(true);

        try{
            
            const response = await resetPassword({userId: id, code: code, newPassword: password});

            if (response.status === 200){
                setIsLoading(false);
                // show reset complete view/panel.
                setResetComplete(true);
            }

        }

        catch(error){

            if (error.response.status === 400 && error.response.data.state === 'expired_or_invalid_code'){
                
                setResetError(true);

            }
            else if (error.response.status === 400 && error.response.data.errors){

                // validation errors.
                const {NewPassword, ResetPasswordCode, UserId} = error.response.data.errors;

                const errors = [...(NewPassword? NewPassword : []), ...(ResetPasswordCode? ResetPasswordCode: []), ...(UserId? UserId :[])];
                

                setValidationErrors(errors);
            }
            else {
                toast.error(error.message);
            }

            setIsLoading(false);
        }

        
         
    }


    let inputStyle = "appearance-none block w-full py-3 px-4 leading-tight text-gray-700 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none";

    function loading(buttonLabel){
        if (isLoading){
            return(<span className=""><CircularProgress size={15} className={classes.buttonProgress} /></span>);
        }
        return buttonLabel;
    }

    function showValidationErrors(){
        return(
            validationErrors.length > 0 ?
        <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm my-5" role="alert">
            <span className="block sm:inline">
                <ul className="list-disc px-5">
                {
                    validationErrors.map((value, index)=>{
                        return <li key={index}> {value} </li>
                    })
                }
                </ul>

            </span>
        </div> 
        : null
        )
    }

    function showResetError(){
        return (
            resetError ? 
            <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm my-5" role="alert">
                <span className="block sm:inline">It looks like the password reset validity has expired. Perhaps try and <a className="font-semibold underline" href="/forgot-password">request a new one</a>.</span>
            </div> 
        : null
        )
    }


    function passwordReset() {
        return(
            <div className="area">
                 <Helmet>
                    <title>OfficeMixer - Reset password</title>
                </Helmet>

                <p className="my-5">Enter your new password.</p>
                <form onSubmit={handleSubmit}>
                    
                    {showResetError()}
                
                    {showValidationErrors()}

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            New Password
                        </label>
                        <input 
                            className={inputStyle} 
                            type={showPassword ? "text": "password"}
                            name="password"
                            placeholder="Password"
                            onChange={(e)=> setPassword(e.target.value)} 
                            required />
                        <div className="flex justify-end mt-2">
                                    <a className="text-sm text-gray-600 cursor-pointer"
                                        onClick={toggleShowHidePassword}>
                                        { showPassword ?  
                                            <div className="flex flex-row"><img src="images/eye-off.svg" width="18" /> <div className="ml-1">hide</div></div> 
                                            : <div className="flex flex-row"><img src="images/eye.svg" width="18" /> <div className="ml-1">show </div></div>}
                                    </a>
                        </div>
                    </div>

                

                    <div className="mb-4">
                        <div className="max-w-xl">
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        </div>

                        <button 
                            type="submit"
                            className="inline-block w-full py-4 px-8 leading-none text-white bg-gray-800 hover:bg-gray-900 rounded shadow"
                            disabled={isLoading}>
                                 {loading('Reset')}
                        </button>

                    </div>

                </form>

            </div>
        )
    }

    function resetSuccess(){
        return(
            <div className="reset-success">

                <p className="my-5">Excellent! Your password has been reset.</p>

                <a 
                    type="submit"
                    className="inline-block w-full py-4 px-8 leading-none text-white bg-gray-800 hover:bg-gray-900 rounded shadow text-center" 
                    href="/login">Login
                </a>

            </div>
        )
    }

    return (
        <div className="mt-16">

            <Helmet>
                <title>OfficeMixer - Reset password</title>
            </Helmet>

            <div className="w-full max-w-md mx-auto flex flex-col items-center"> 

                <img src="/images/officeMixerLogoblueblack.svg" className=" h-16 w-16" />
                <h1 className="w-full mx-auto text-center font-semibold my-6 tracking-tight">
                    OfficeMixer
                </h1>

                <div className="lg:border w-full p-5 rounded mt-8">
    
                    <h1 className="text-2xl text-gray-800 font-semibold mb-6">Reset Password</h1>
    
                    { resetComplete ? resetSuccess() : passwordReset() }
                    
    
                    <div className="flex justify-between">
                        
                        <div>
                            <a href="/register" className="text-sm text-gray-700">Create an account</a>
                        </div>
                        <div>
                            <a href="/login" className="text-sm text-gray-700">I remember my password</a>
                        </div>
    
                    </div>
    
                </div>
    
            </div>

        </div>
    )

    
}

export default ResetPassword