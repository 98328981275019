import React, {useState} from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
import SideNav from "../components/SideNav";
import TeamForm from "../components/TeamForm";
import { postTeam } from "../Api/Teams";
import { toast } from "react-toastify";


function TeamAdd(){

    // get floorId from query string param
    const search = useLocation().search;
    const floorId = new URLSearchParams(search).get("floorId");

    const history = useHistory();

    const [name, setName] = useState("")

    const addTeam = async () => {

        try{ 
            const response = await postTeam({
                name: name,
                floorId: floorId
            });

            // redirect to page
            history.push(`/organisation/floors/${floorId}`);
            window.location = `/organisation/floors/${floorId}`;
        }
        catch(error){
            toast.error(error.message);
        }
    }


    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

                <Helmet>
                    <title>OfficeMixer - Settings</title>
                </Helmet>  

                <div className="sm:w-full md:max-w-4xl md:mx-auto flex flex-col md:flex-row">
                    
                    <div className="settings-content-container px-6 w-full">
                    <h3 className="text-3xl font-semibold leading-8 border-b pb-4">Add Team</h3>
                        <TeamForm name={name} onNameChange={setName} onSave={addTeam} />
                    </div>
                </div>

               

            </div>

        </div>
    )

    
}

export default TeamAdd;