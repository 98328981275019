import React, { useEffect, useState } from "react";
// import { ToastProvider, useToasts } from 'react-toast-notifications'
import Navbar from "./Navbar";
import { getWeeklyAnalytics, getMonthlyAnalytics } from "../utils/Api.js";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Chart from 'chart.js';
import moment from 'moment';
import _ from 'lodash';
import { DateTime } from 'luxon';

// https://codesandbox.io/s/o57l2?file=/demo.js:925-940
const useStyles = makeStyles((theme) => ({

    buttonProgress: {
      color: 'white',
    },
  }));

toast.configure();

function Analytics(){

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const weekNumber = moment().isoWeek();

    const dt = DateTime.now();
    const theYear = dt.year;
    const month = dt.month;
    const day = dt.day;

    

    useEffect(()=>{

        getMonthlyAnalytics(theYear, month, day)
        .then((response)=>{

            console.log(response.data);

            loadChart({
                labels: response.data.chartLabels,
                dataPoints: response.data.data,
                max: response.data.maxOccupancy
            });
        })
        .catch((error)=>{
            console.log(error);
        });


        

    


    },[]);

    function loadChart({labels, dataPoints, max}){

        var ctx = document.getElementById('chart-stats').getContext('2d');

        let maxYAxisPoint = _.max(dataPoints) + 1;

        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',
            responsive: true,
            // The data for our dataset
            data: {
                // labels: ['Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11'],
                labels: labels,
                datasets: [{
                    label: 'Attendees',
                    backgroundColor: 'rgba(255, 99, 132, 0.45)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    data: dataPoints
                    // data: [0, 0, 5, 2, 0, 3, 4]
                }]
            },

            // Configuration options go here
            options: {
                legend:{
                    display: false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgb(242,240,240)'
                        } 
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'rgb(242,240,240)'
                        },
                        ticks:{
                            beginAtZero: true,
                            max: maxYAxisPoint,
                            stepSize: 1
                        }
                    }]
                }
            }
        });
    }


    function loading(buttonLabel){
        
        if (isLoading){
            return(<span className=""><CircularProgress size={15} className={classes.buttonProgress} /></span>);
        }
        return buttonLabel;
    }

    return(
        <div>
            <Navbar />

            <div className="flex flex-col mt-8 md:mt-16">

            <Helmet>
                <title>OfficeMixer - Analytics</title>
            </Helmet>  

                <div className="sm:w-full md:max-w-3xl md:mx-auto border-solid border-gray-400 border rounded p-3">

                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-2xl leading-6 font-medium text-gray-900">
                            Analytics - last 30 days
                        </h3>
                    </div>

                    <canvas id="chart-stats" height="130"></canvas>

                </div>


            </div>

        </div>
    )
}

export default Analytics