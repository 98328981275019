import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

function TeamList({teams, onDeleteTeam}){


    const onDeleteTeamClicked = async (event, item) =>{
        event.preventDefault();

        const willDelete = await swal({
            
            title: "Are you sure?",
            text: "Deleting this team will permanently delete any users associated with it.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            
        });

        if (willDelete){
            console.log("delete it");
            onDeleteTeam(item);

        }
        else {
            console.log("nothing happened");
        }


    }

    const renderTeams = (item, index) =>{

        return(
            <div className="border border-gray-300 leading-9 shadow-sm rounded py-3 px-4" key={index}>
                
                <div className="flex">
                    <div className="flex-grow">
                        <h3 className="floor-title text-lg font-semibold text-gray-700">
                            <a href={"/teams/"+ item.id} className="hover:text-blue-700">{item.name}</a>
                        </h3>
                    </div>

                    <div className="mt-1">
                        <a onClick={ (e) => onDeleteTeamClicked(e, item)} className="cursor-pointer ">

                            <svg className="text-gray-600 hover:text-blue-700" width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
                                    fill="currentColor"
                                />
                                <path d="M9 9H11V17H9V9Z" fill="currentColor" />
                                <path d="M13 9H15V17H13V9Z" fill="currentColor" />
                            </svg>
                            
                        </a>
                    </div>

                </div>

                <div className="mt-2">
                    <div className="text-3xl leading-10 font-semibold">
                        {item.users.length}
                    </div>
                    <div className="uppercase text-xs leading-5 font-semibold text-gray-600">
                        People
                    </div>
                </div>

            </div>
        )

    }

    return(
        <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">

                { teams.length > 0 ?
                    teams.map(renderTeams) : null }

            </div>
        </div>    
    )

    
}

export default TeamList;